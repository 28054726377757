import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getAllFunds, getPdfLinks } from "../../apis/api-endpoint";
import useStore from "../../store";
import { toast } from "react-hot-toast";
import { PdfLink, Fund, TableMap } from "../Types";

const Optimization = () => {
  const { displayLoader, hideLoader, setCode } = useStore();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [tableData, setTableData] = useState<Fund[]>([]);
  const [pdfLinks, setPdfLinks] = useState<PdfLink[]>([]);

  useEffect(() => {
    const handleFunds = async () => {
      displayLoader();
      try {
        const response = await getAllFunds();

        if (response?.status) {
          setTableData(response?.data?.allFunds);
          hideLoader();
        } else {
          hideLoader();
          // Handle the case when response is undefined or falsy
          toast.error("Failed to fetch funds data.");
        }
      } catch (error) {
        hideLoader();
        // Handle the error here, for example:
        toast.error("An error occurred while fetching funds data.");
      }
    };

    handleFunds();
  }, [hideLoader, displayLoader]);

  useEffect(() => {
    const handleFunds = async () => {
      displayLoader();
      try {
        const response = await getPdfLinks({ pdfType: "past_performance" });
        if (response?.status) {
          setPdfLinks(response?.data?.pdfLinks);
          hideLoader();
        } else {
          hideLoader();
          // Handle the case when response is undefined or falsy
          // toast.error("Failed to fetch funds data.");
        }
      } catch (error) {
        hideLoader();
        // Handle the error here, for example:
        // toast.error("An error occurred while fetching funds data.");
      }
    };

    handleFunds();
  }, [hideLoader, displayLoader]);

  const handleNavigate = (
    id: string,
    code: string,
    fundName: string,
    haveFundDetails: boolean
  ) => {
    if (haveFundDetails) {
      navigate(`/funds/${id}`);
      setCode(code);
    }
  };

  const handleItalianOPenLink = () => {
    const italianLink = pdfLinks.find((link) => link.language === "italian");
    if (italianLink?.url) {
      window.open(`${italianLink?.url}`, "_blank", "noopener, noreferrer");
    }
  };

  const handleIEnglishOPenLink = () => {
    const englishLink = pdfLinks.find((link) => link.language === "english");
    if (englishLink?.url) {
      window.open(`${englishLink?.url}`, "_blank", "noopener, noreferrer");
    }
  };

  const handleGermanOPenLink = () => {
    const germanLink = pdfLinks.find((link) => link.language === "german");
    if (germanLink?.url) {
      window.open(`${germanLink?.url}`, "_blank", "noopener, noreferrer");
    }
  };
  return (
    <div>
      <Navbar />
      <div className="background-container">
        <div className="text-wrap">
          <p className="benvenuti">
            {t("Optimize")} <br /> {t("your choices")}
          </p>
          <p className="benvenutii">{t("Optimize your choices")}</p>

          <h6>
            {t("Discover the different tailor-made")}
            <br />
            {t("solutions for a personalized investment")}
            <br />
            {t("through our range of funds.")}
          </h6>
        </div>
      </div>
      <div className="panoramic">
        <div className="pano-txt-wrap">
          <p className="panoramica">
            {" "}
            {t("Overview of ASB Axion SICAV Sub-Funds")}
          </p>
          {/* <p className="cap">
            {t("(CAP, Capitalization - DIS, Distribution-N, net performance)")}
          </p> */}
        </div>
        <div className="pano-table">
          <table className="table pano-tab">
            <thead>
              <tr>
                <th scope="col">{t("FUNDS")}</th>
                <th scope="col">{t("SHARE CLASS")}</th>
                <th scope="col">ISIN</th>
                <th scope="col">BLOOMBERG</th>
                <th scope="col">{t("TYPOLOGY")}</th>
                <th scope="col">{t("LAST NAV")}</th>
                <th scope="col">AUM</th>
                <th scope="col">YTD</th>
                <th scope="col">{t("3 MONTHS N")}</th>
                <th scope="col">{t("1 YEAR N")}</th>
                <th scope="col">{t("3 YEARS N")}</th>
                <th scope="col">{t("5 YEARS N")}</th>
              </tr>
            </thead>
            <tbody>
              {tableData?.map((funds: TableMap) => {
                return (
                  <tr key={funds?.isinCode}>
                    <th
                      scope="row"
                      onClick={() =>
                        handleNavigate(
                          funds?.isinCode,
                          funds?.code,
                          funds?.description,
                          funds?.haveFundDetails
                        )
                      }
                    >
                      {funds?.description}
                    </th>
                    <td>{funds?.shareClass}</td>
                    <td>{funds?.isinCode}</td>
                    <td>{funds?.bloomberg}</td>
                    <td>{funds?.tipology}</td>
                    <td>{funds?.ultimoNav}</td>
                    <td>{funds?.netAssetValue}</td>
                    <td>{funds?.TRRYtd}</td>
                    <td>{funds?.TRR3Month || "-"}</td>
                    <td>{funds?.TRR1Year || "-"}</td>
                    <td>{funds?.TRR3Year || "-"}</td>
                    <td>{funds?.TRR5Year || "-"}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="fonte-txt-wrap">
          <p className="paragraph-text">
            {t("DIS: Distribution Share Class")}
            <br />
            {t("CAP: Accumulation Share Class")}
            <br />
            {t(
              "Annualized performance for horizons longer than one year, total return for distribution share classes"
            )}
            <br />
            <br />
            {t(
              "Data source: Axion SWISS Bank SA. Data updated to the previous business day."
            )}
            <br />
            {t(
              "Past performance does not guarantee and is not indicative of future results."
            )}
          </p>

          {i18n.language === "it" && (
            <button className="small-red-text" onClick={handleItalianOPenLink}>
              Commento mensile dei mercati
            </button>
          )}
          {i18n.language === "en" && (
            <button className="small-red-text" onClick={handleIEnglishOPenLink}>
              Monthly Market Commentary
            </button>
          )}
          {i18n.language === "en" && (
            <button
              className="small-red-text mrgn-left"
              onClick={handleGermanOPenLink}
            >
              Monatliche Marktstatistik
            </button>
          )}
        </div>
      </div>
      <div className="d-flex">
        <div className="address">
          <p className="fw-bold">{t("How to invest")}</p>
          <p className="paragraph-text">
            {t(
              "Axion SWISS Bank Ltd is authorized to distribute ASB Axion SICAV investment funds in Switzerland and Luxembourg. An exhaustive list of the placement methods and products"
            )}
            <br />
            {t(
              "offered is available. For any additional information, please contact us by email at:"
            )}
            <span style={{ color: "#ff172e" }}>info@axionsicav.com</span>
          </p>
          <p className="fw-bold">{t("Global Distributor:")}</p>
          <p className="paragraph-text">
            {t(
              "Axion SWISS Bank SA, Viale S. Franscini 22, CH-6901 Lugano - Svizzera - Tel. +41 91 910 95 10"
            )}
          </p>
          <p className="fw-bold">{t("Global Distributor:")}</p>
          <p className="paragraph-text">
            BancaStato, Viale Gen. H. Guisan 5, 6500 Bellinzona - Svizzera -
            Tel. +41 91 803 71 11
          </p>
          <p className="fw-bold">{t("Swiss Representative")}</p>
          <p className="paragraph-text">
            CACEIS (Switzerland) SA, Route de Signy, 35, CH-1260 Nyon/VD, Suisse
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Optimization;
