import React, { useEffect, useState } from "react";
import { ChangeEvent } from "react";
import { uploadFactsheetPDF } from "../../apis/api-endpoint";
import useStore from "../../store";
import { toast } from "react-hot-toast";

const UploadFactsheet = ({ fundcode, selectedFund }: any) => {
  const { hideLoader, displayLoader } = useStore();
  const [selectedPDF, setSelectedPDF] = useState<any>({});
  const [fundType, setFundType] = useState<string>("");
  const [pdfError, setPdfError] = useState(false);
  const [fundTypeError, setFundTypeError] = useState(false);

  useEffect(() => {
    if (selectedPDF.name !== "" || selectedPDF.name !== undefined)
      setPdfError(false);
    if (fundType !== "") setFundTypeError(false);
  }, [selectedPDF, fundType]);

  const handleSelectPDF = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      setSelectedPDF(file);
    }
  };

  const handleValidationPDF = () => {
    if (selectedPDF.name === "" || selectedPDF.name === undefined)
      setPdfError(true);
    if (fundType === "") setFundTypeError(true);
  };

  const handlePDFUpload = async () => {
    handleValidationPDF();

    if (selectedPDF.name !== undefined && fundType !== "") {
      displayLoader();
      const formData = new FormData();
      formData.append("pdf", selectedPDF);
      formData.append("pdfType", fundType);
      formData.append("code", fundcode);

      try {
        const response = await uploadFactsheetPDF(formData);
        hideLoader();
        if (response?.status) {
          toast.success(response?.data.msg);
          setFundType("");
          //   const res = await getAllFundsNames();
          //   if (res?.status) {
          //     setAllFunds(res.data.fundDetails);
          //   }
          const fileInput = document.getElementById(
            "fileInputPdffact"
          ) as HTMLInputElement;
          if (fileInput) {
            fileInput.value = "";
          }
          setSelectedPDF({});
        } else {
          // Handle response status other than 200
        }
      } catch (error) {
        hideLoader();
        // Handle the error here, for example:
        toast.error("An error occurred while uploading the PDF.");
      }
    }
  };
  return (
    <>
      <div className="file">
        <div>
          <div className=" d-flex gap-2">
            <div>
              <input
                id="fileInputPdffact"
                type="file"
                accept=".pdf"
                onChange={handleSelectPDF}
                className="file-input"
              />
              {pdfError && (
                <p className="user-required-text">Please Choose File</p>
              )}
              <div className=" mt-4 ps-3 ">
                <p className="paragraph-text">{selectedPDF?.name}</p>
              </div>
            </div>
            <div>
              <select
                id="fund"
                name="funds"
                value={fundType}
                className="select-input"
                onChange={(e) => setFundType(e.target.value)}
              >
                <option value="">Select Fund</option>
                <option value="EUR - CHF IT">EUR - CHF IT</option>
                <option value="EUR - CHF EN">EUR - CHF EN</option>
                <option value="EUR - CHF DE">EUR - CHF DE</option>
              </select>
              {fundTypeError && <p className="user-required-text">Required</p>}
            </div>
            <div>
              <button
                className="btn1"
                style={{ marginTop: "0px" }}
                onClick={handlePDFUpload}
              >
                Upload Factsheet
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadFactsheet;
