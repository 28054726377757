import React, { useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Cookies = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Navbar />

      <div className="privacy-main">
        <p className="red-text">{t("Cookies1")}</p>
        <p className="paragraph-text">{t("Cookies para")}</p>
        <div className="mt-5" style={{ lineHeight: "4px" }}>
          <p
            className="privacy-txt"
            onClick={() => navigate(`/avvertenze-legali`)}
          >
            {t("General Warnings")}
          </p>
          <p className="privacy-txt" onClick={() => navigate(`/condizioni`)}>
            {t("Conditions")}
          </p>
          <p className="privacy-txt" onClick={() => navigate(`/privacy`)}>
            {t("Privacy")}
          </p>
          <p className="privacy-txt" onClick={() => navigate(`/cookies`)}>
            {t("Cookies1")}
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Cookies;
