import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PdfLinkDetailFund } from "../Types";

const DOCUMENTATION = ({ pdfLinks }: { pdfLinks: PdfLinkDetailFund[] }) => {
  const { t, i18n } = useTranslation();
  const [openMenuProspectus, setOpenMenuProspectus] = useState(false);
  const [openMenuKiid, setOpenMenuKiid] = useState(false);
  const [openMenuFact, setOpenMenuFact] = useState(false);

  const prospettolinks = pdfLinks?.filter((pdf: PdfLinkDetailFund) => {
    return pdf?.type === "PROSPETTO";
  });

  const kiidlinksEg = pdfLinks?.filter((pdf: PdfLinkDetailFund) => {
    return pdf?.category === "kidd" && pdf?.language === "italian";
  });

  const kiidlinksGr = pdfLinks?.filter((pdf: PdfLinkDetailFund) => {
    return pdf?.category === "kidd" && pdf?.language !== "italian";
  });

  const factslinksEn = pdfLinks?.filter((pdf: PdfLinkDetailFund) => {
    return pdf?.category === "fact-sheet" && pdf?.language !== "italian";
  });

  const factslinksIt = pdfLinks?.filter((pdf: PdfLinkDetailFund) => {
    return pdf?.category === "fact-sheet" && pdf?.language === "italian";
  });

  kiidlinksEg?.forEach((document: PdfLinkDetailFund) => {
    switch (document.type) {
      case "KIID_CAPITALIZZAZIONE_EUR":
        document.name = "CAPITALIZZAZIONE EUR";
        break;
      case "KIID_CAPITALIZZAZIONE_CHF":
        document.name = "CAPITALIZZAZIONE CHF";
        break;
      case "KIID_DISTRIBUZIONE_CHF":
        document.name = "DISTRIBUZIONE CHF";
        break;
      case "KIID_DISTRIBUZIONE_EUR":
        document.name = "DISTRIBUZIONE EUR";
        break;
      case "KIID_THESAURIEREND_CHF":
        document.name = "THESAURIEREND CHF";
        break;
      case "KIID_THESAURIEREND_EUR":
        document.name = "THESAURIEREND EURF";
        break;
      case "KIID_AUSSCHÜTTEND_CHF":
        document.name = "AUSSCHÜTTEND CHF";
        break;
      case "KIID_AUSSCHÜTTEND_EUR":
        document.name = "AUSSCHÜTTEND EUR";
        break;
      default:
        break;
    }
  });

  kiidlinksGr?.forEach((document: PdfLinkDetailFund) => {
    switch (document.type) {
      case "KIID_CAPITALIZATION_EUR":
        document.name = "CAPITALIZATION  EUR";
        break;
      case "KIID_CAPITALIZATION_CHF":
        document.name = "CAPITALIZATION CHF";
        break;
      case "KIID_DISTRIBUTION_CHF":
        document.name = "DISTRIBUTION CHF";
        break;
      case "KIID_DISTRIBUTION_EUR":
        document.name = "DISTRIBUTION EUR";
        break;
      case "KIID_THESAURIEREND_CHF":
        document.name = "THESAURIEREND CHF";
        break;
      case "KIID_THESAURIEREND_EUR":
        document.name = "THESAURIEREND EURF";
        break;
      case "KIID_AUSSCHÜTTEND_CHF":
        document.name = "AUSSCHÜTTEND CHF";
        break;
      case "KIID_AUSSCHÜTTEND_EUR":
        document.name = "AUSSCHÜTTEND EUR";
        break;
      default:
        break;
    }
  });

  const handlePropectusMenu = () => {
    if (openMenuProspectus) {
      setOpenMenuProspectus(false);
    } else {
      setOpenMenuProspectus(true);
      setOpenMenuKiid(false);
      setOpenMenuFact(false);
    }
  };
  const handleKiidMenu = () => {
    if (openMenuKiid) {
      setOpenMenuKiid(false);
    } else {
      setOpenMenuKiid(true);
      setOpenMenuFact(false);
      setOpenMenuProspectus(false);
    }
  };
  const handleFactMenu = () => {
    if (openMenuFact) {
      setOpenMenuFact(false);
    } else {
      setOpenMenuFact(true);
      setOpenMenuProspectus(false);
      setOpenMenuKiid(false);
    }
  };
  const handleOPenLinkProspectus = () => {
    const italianProspettoPdf = prospettolinks?.find(
      (pdf) => pdf.language === "italian"
    );
    const englishProspettoPdf = prospettolinks?.find(
      (pdf) => pdf.language === "english"
    );
    if (i18n.language === "it" && italianProspettoPdf?.link !== undefined) {
      window.open(
        `${italianProspettoPdf.link}`,
        "_blank",
        "noopener, noreferrer"
      );
    } else if (i18n.language === "en" && englishProspettoPdf !== undefined) {
      window.open(
        `${englishProspettoPdf.link}`,
        "_blank",
        "noopener, noreferrer"
      );
    }
  };

  const handleOPenLinkProspekt = () => {
    const germenProspettoPdf = prospettolinks?.find(
      (pdf) => pdf.language === "german"
    );

    if (germenProspettoPdf?.link !== undefined) {
      window.open(
        `${germenProspettoPdf.link}`,
        "_blank",
        "noopener, noreferrer"
      );
    }
  };

  const handleOPenLinkKiid = (link: string) => {
    window.open(`${link}`, "_blank", "noopener, noreferrer");
  };

  const handleOPenLinkFact = (link: string) => {
    window.open(`${link}`, "_blank", "noopener, noreferrer");
  };

  return (
    <div>
      <p>{t("DOCUMENTATION")}</p>
      <div className="three-btns">
        <button className="btn2" onClick={handlePropectusMenu}>
          {t("PROSPECTUS")}
        </button>
        <button className="btn2" onClick={handleKiidMenu}>
          KIID
        </button>
        <button className="btn2" onClick={handleFactMenu}>
          FACTSHEET
        </button>
      </div>
      {openMenuProspectus && (
        <div className="btn-menu">
          <div className="btn-menu-text-wrape d-flex align-items-end justify-content-center">
            <p onClick={handleOPenLinkProspectus}>{t("PROSPECTUS")}</p>
          </div>
          {i18n.language === "en" && (
            <div className="btn-menu-text-wrape d-flex align-items-end justify-content-center">
              <p onClick={handleOPenLinkProspekt}>PROSPEKT</p>
            </div>
          )}
        </div>
      )}
      {openMenuKiid && (
        <div className="btn-menu-2">
          {i18n.language === "it" ? (
            <>
              {kiidlinksEg?.map((pdf: PdfLinkDetailFund) => {
                return (
                  <div className="btn-menu-text-wrape d-flex align-items-center justify-content-center">
                    <p onClick={() => handleOPenLinkKiid(pdf?.link)}>
                      {pdf?.name}
                    </p>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              {kiidlinksGr?.map((pdf: PdfLinkDetailFund) => {
                return (
                  <div className="btn-menu-text-wrape d-flex align-items-center justify-content-center">
                    <p onClick={() => handleOPenLinkKiid(pdf?.link)}>
                      {pdf?.name}
                    </p>
                  </div>
                );
              })}
            </>
          )}
        </div>
      )}
      {openMenuFact && (
        <div className="btn-menu-3">
          {i18n.language === "it" ? (
            <>
              {factslinksIt?.map((pdf: PdfLinkDetailFund) => {
                return (
                  <div className="btn-menu-text-wrape d-flex align-items-center justify-content-center">
                    <p onClick={() => handleOPenLinkFact(pdf?.link)}>
                      {pdf?.type}
                    </p>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              {factslinksEn?.map((pdf: PdfLinkDetailFund) => {
                return (
                  <div className="btn-menu-text-wrape d-flex align-items-center justify-content-center">
                    <p onClick={() => handleOPenLinkFact(pdf?.link)}>
                      {pdf?.type}
                    </p>
                  </div>
                );
              })}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default DOCUMENTATION;
