import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { verifyOTP } from "../../apis/api-endpoint";
import useStore from "../../store";
import { toast } from "react-hot-toast";

const VerifyEmail = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { displayLoader, hideLoader, userData } = useStore();
  const [verfyCode, setVerifyCode] = useState("");
  const [codeError, setCodeError] = useState(false);

  useEffect(() => {
    if (verfyCode !== "") {
      setCodeError(false);
    }
  }, [verfyCode]);

  const handleError = () => {
    if (verfyCode === "") {
      setCodeError(true);
    }
  };

  const handleVerifyOTP = async () => {
    handleError();
    if (verfyCode !== "") {
      displayLoader();
      const response: any = await verifyOTP({
        otp: verfyCode,
        userId: userData?.id,
      });

      if (response instanceof Error) {
        //@ts-ignore
        toast.error(response?.response?.data?.errors);
        hideLoader();
      } else if (response?.data?.status) {
        navigate("/admin");
        localStorage.setItem("token", response.data.data.token);
        hideLoader();
      } else {
        hideLoader();
      }
    }
  };

  return (
    <div>
      <Navbar />
      <div className="background-container">
        <div className="text-wrap">
          <p className="benvenuti">
            {t("Welcome in ASB")} <br /> {t("Axion SICAV!")}
          </p>
          <p className="benvenutii">{t("Benvenuti in ASB Axion SICAV!")}</p>

          <h6>
            {t("To access the portal we kindly invite you")} <br />{" "}
            {t("to read the legal disclaimer and to accept")}
            <br /> {t("the user's conditions")}
          </h6>
        </div>
        <div className="login-box">
          <div className="login-box-blur d-flex align-items-center justify-content-center">
            <div className="login-box-content">
              <p className="text-center" style={{ fontSize: "16px" }}>
                Check email and enter verify code
              </p>
              <div>
                <div className="d-flex justify-content-center">
                  <input
                    type="text"
                    placeholder="Enter code"
                    value={verfyCode}
                    onChange={(e) => setVerifyCode(e.target.value)}
                  />
                </div>
                {codeError && <p className="required-text">Required</p>}
              </div>
              <div className="confirm-box-btns mt-4">
                <button className="btn2" onClick={handleVerifyOTP}>
                  Verify
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
