import React from "react";
import { useTranslation } from "react-i18next";
import useStore from "../../store";

const AnnuallyTable = ({ performanceData }: any) => {
  const { fundName } = useStore();
  const { t } = useTranslation();

  return (
    <>
      <p className="analisi-txt">
        {t("Current key fund summary")}{" "}
        <span style={{ color: "#000" }}>EUR - CHF</span>
      </p>
      {/* <p className="eru-cls-txt">
        {t("(CAP, Capitalization - DIS, Distribution - N, net performance)")}
      </p> */}
      <div className="funds-table">
        <table className="table pano-tab">
          <thead>
            <tr>
              <th scope="col">{t("FUNDS")}</th>
              <th scope="col">{t("SHARE CLASS")}</th>
              <th scope="col">ISIN</th>
              <th scope="col">BLOOMBERG</th>
              <th scope="col">{t("TYPOLOGY")}</th>
              <th scope="col">{t("LAST NAV")}</th>
              <th scope="col">AUM</th>
              <th scope="col">YTD</th>
              <th scope="col">{t("3 MONTHS N")}</th>
              <th scope="col">{t("1 YEAR N")}</th>
              <th scope="col">{t("3 YEARS N")}</th>
              <th scope="col">{t("5 YEARS N")}</th>
            </tr>
          </thead>
          <tbody>
            {performanceData?.map((items: any) => {
              return (
                <tr key={items?.isin}>
                  <th scope="row">{fundName}</th>
                  <td>{items?.shareClass}</td>
                  <td>{items?.isinCode}</td>
                  <td>{items?.bloomberg}</td>
                  <td>{items?.tipology}</td>
                  <td>{items?.ultimoNav}</td>
                  <td>{items?.netAssetValue}</td>
                  <td>{items?.TRRYtd}</td>
                  <td>{items?.TRR3Month || "-"}</td>
                  <td>{items?.TRR1Year || "-"}</td>
                  <td>{items?.TRR3Year || "-"}</td>
                  <td>{items?.TRR5Year || "-"}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="aggiornati-txt-wrap">
        <p className="paragraph-text">
          {t("DIS: Distribution Share Class")}
          <br />
          {t("CAP: Accumulation Share Class")}
          <br />
          {t(
            "Annualized performance for horizons longer than one year, total return for distribution share classes"
          )}
          <br />
          <br />
          {t(
            "Data source: Axion SWISS Bank SA. Data updated to the previous business day."
          )}
          <br />
          {t(
            "Past performance does not guarantee and is not indicative of future results."
          )}
        </p>
      </div>
    </>
  );
};

export default AnnuallyTable;
