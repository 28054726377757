import React, { useState, useEffect } from "react";
import { ChangeEvent } from "react";
import useStore from "../../store";
import { toast } from "react-hot-toast";
import { uploadPDF } from "../../apis/api-endpoint";

const RemunerationPolicy = () => {
  const { hideLoader, displayLoader } = useStore();
  const [selectedPolicy, setSelectedPolicy] = useState<any>({});
  const [policyError, setPolicyError] = useState(false);
  const [policyLanguageError, setPolicyLanguageError] = useState(false);
  const [policyLanguageSelect, setPolicyLanguageSelect] = useState("");

  useEffect(() => {
    if (selectedPolicy.name !== "" || selectedPolicy.name !== undefined) {
      setPolicyError(false);
    }
    if (policyLanguageSelect !== "") setPolicyLanguageError(false);
  }, [selectedPolicy, policyLanguageSelect]);

  const handleSelectPolicy = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      setSelectedPolicy(file);
    }
  };

  const handleValidationPolicy = () => {
    if (selectedPolicy.name === "" || selectedPolicy.name === undefined) {
      setPolicyError(true);
    } else if (policyLanguageSelect === "") {
      setPolicyLanguageError(true);
    }
  };

  const handlePolicyUpload = async () => {
    handleValidationPolicy();
    if (selectedPolicy.name !== undefined && policyLanguageSelect !== "") {
      displayLoader();
      const formData = new FormData();
      formData.append("pdf", selectedPolicy);
      formData.append("pdfType", "Remuneration_Policy");
      formData.append("language", policyLanguageSelect);
      try {
        const response = await uploadPDF(formData);
        hideLoader();
        if (response?.status) {
          toast.success(response?.data.msg);
          //   setFundType("");
          const fileInput = document.getElementById(
            "fileInputPdfPolicyRemuneration"
          ) as HTMLInputElement;
          if (fileInput) {
            fileInput.value = "";
          }
          setSelectedPolicy({});
          setPolicyLanguageSelect("");
        } else {
          // Handle response status other than 200
        }
      } catch (error) {
        hideLoader();
        // Handle the error here, for example:
        toast.error("An error occurred while uploading the PDF.");
      }
    }
  };
  return (
    <>
      <div className="file-box">
        <p className="file-text">Upload Remuneration Policy</p>
        <div className="file">
          <div>
            <input
              id="fileInputPdfPolicyRemuneration"
              type="file"
              accept=".pdf"
              onChange={handleSelectPolicy}
              className="file-input"
            />
            {policyError && (
              <p className="file-required-text ps-4">Please Choose File</p>
            )}
          </div>
          <div>
            <select
              id="fund"
              name="funds"
              value={policyLanguageSelect}
              className="select-input"
              onChange={(e) => setPolicyLanguageSelect(e.target.value)}
            >
              <option value="">Select Language</option>
              <option value="italian">Italian</option>
              <option value="english">English</option>
              <option value="german">German</option>
            </select>
            {policyLanguageError && (
              <p className="file-required-text ps-4">Required</p>
            )}
          </div>
          <div>
            <button className="btn1 mt-0" onClick={handlePolicyUpload}>
              Remuneration Policy
            </button>
          </div>
        </div>

        <div className="file-name-list">
          <p className="paragraph-text">{selectedPolicy?.name}</p>
        </div>
      </div>
    </>
  );
};

export default RemunerationPolicy;
