import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { useTranslation } from "react-i18next";
import { PdfLink } from "../Types";
import { getPdfLinks } from "../../apis/api-endpoint";
import useStore from "../../store";

const MarketView = () => {
  const { i18n } = useTranslation();
  const { displayLoader, hideLoader } = useStore();
  const [pdfLinks, setPdfLinks] = useState<PdfLink[]>([]);

  useEffect(() => {
    const handleFunds = async () => {
      displayLoader();
      try {
        const response = await getPdfLinks({ pdfType: "past_performance" });
        if (response?.status) {
          setPdfLinks(response?.data?.pdfLinks);
          hideLoader();
        } else {
          hideLoader();
          // Handle the case when response is undefined or falsy
          // toast.error("Failed to fetch funds data.");
        }
      } catch (error) {
        hideLoader();
        // Handle the error here, for example:
        // toast.error("An error occurred while fetching funds data.");
      }
    };

    handleFunds();
  }, [hideLoader, displayLoader]);

  const handleItalianOPenLink = () => {
    const italianLink = pdfLinks.find((link) => link.language === "italian");
    if (italianLink?.url) {
      window.open(`${italianLink?.url}`, "_blank", "noopener, noreferrer");
    }
  };

  const handleIEnglishOPenLink = () => {
    const englishLink = pdfLinks.find((link) => link.language === "english");
    if (englishLink?.url) {
      window.open(`${englishLink?.url}`, "_blank", "noopener, noreferrer");
    }
  };

  const handleGermanOPenLink = () => {
    const germanLink = pdfLinks.find((link) => link.language === "german");
    if (germanLink?.url) {
      window.open(`${germanLink?.url}`, "_blank", "noopener, noreferrer");
    }
  };

  return (
    <div>
      <Navbar />

      <div className="privacy-main mb-5">
        <div>
          <div>
            {i18n.language === "it" && (
              <button
                className="small-red-text"
                onClick={handleItalianOPenLink}
              >
                Commento mensile dei mercati
              </button>
            )}
          </div>
          <div>
            {i18n.language === "en" && (
              <button
                className="small-red-text"
                onClick={handleIEnglishOPenLink}
              >
                Monthly Market Commentary
              </button>
            )}
            {i18n.language === "en" && (
              <button
                className="small-red-text mrgn-left"
                onClick={handleGermanOPenLink}
              >
                Monatliche Marktstatistik
              </button>
            )}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default MarketView;
