import React, { useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Conditions = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Navbar />

      <div className="privacy-main">
        <p className="red-text">{t("Condizioni")}</p>
        <p className="paragraph-text">
          {/* {t(
            "Welcome to the www.axionbank.ch website (hereafter the “Website”)."
          )} */}
          {/* <br /> */}
          {t("Condizioni para 1")} <br />
          {t("Condizioni para 2")}
          <br />
          {t("Condizioni para 3")}
        </p>
        <div>
          <p className="fw-bold">{t("A. Change in our Terms of Use")}</p>
          <p className="paragraph-text">
            {t("Change in our Terms of Use para")}
          </p>
        </div>
        <div>
          <p className="fw-bold">{t("B. Target users")}</p>
          <p className="paragraph-text">
            {t("Target users para 1")} <br /> {t("Target users para 2")} <br />{" "}
            {t("Target users para 3")} <br /> {t("Target users para 4")} <br />{" "}
            {t("Target users para 5")}
          </p>
        </div>
        <div>
          <p className="fw-bold">{t("C. No product or services offered")}</p>
          <p className="paragraph-text">
            {t("No product or services offered para 1")} <br />{" "}
            {t("No product or services offered para 2")}
          </p>
        </div>
        <div>
          <p className="fw-bold">{t("D. Restrictions to the use of email")}</p>
          <p className="paragraph-text">
            {t("Restrictions to the use of email para 1")}
          </p>
        </div>
        <div>
          <p className="fw-bold">
            {t("E. Website content and external links")}
          </p>
          <p className="paragraph-text">
            {t("Website content and external link para 1")}
          </p>
        </div>
        <div>
          <p className="fw-bold">
            {t("F. Intellectual property and our policy concerning web links")}
          </p>
          <p className="paragraph-text">
            {t(
              "Intellectual property and our policy concerning web links para 1"
            )}
            <br />
            {t(
              "Intellectual property and our policy concerning web links para 2"
            )}
          </p>
        </div>
        <div>
          <p className="fw-bold">{t("G. Guarantee exclusion")}</p>
          <p className="paragraph-text">
            {t("Guarantee exclusion para 1")}
            <br /> {t("Guarantee exclusion para 2")} <br />
            {t("Guarantee exclusion para 3")}
          </p>
        </div>
        <div>
          <p className="fw-bold">{t("H. User behavior")}</p>
          <p className="paragraph-text">
            {t("User behavior para 1")} <br /> {t("User behavior para 2")}
            <br />
            {t("User behavior para 3")} <br /> {t("User behavior para 4")}
            <br /> {t("User behavior para 5")}
            <br />
            {t("User behavior para 6")}
            <br />
            {t("User behavior para 7")}
          </p>
        </div>
        <div>
          <p className="fw-bold">
            {t("I. Relations with third party suppliers of goods and services")}
          </p>
          <p className="paragraph-text">
            {t(
              "Relations with third party suppliers of goods and services para 1"
            )}
          </p>
        </div>
        <div>
          <p className="fw-bold">{t("L. Compensation")}</p>
          <p className="paragraph-text">{t("Compensation para 1")}</p>
        </div>
        <div>
          <p className="fw-bold">{t("M. Abstention or inaction")}</p>
          <p className="paragraph-text">{t("Abstention or inaction para 1")}</p>
        </div>
        <div>
          <p className="fw-bold">{t("N. Separability")}</p>
          <p className="paragraph-text">{t("Separability para 1")}</p>
        </div>
        <div>
          <p className="fw-bold">
            {t(
              "O. CLAUSE ON EXTENDED JURISDICTION AND CHOICE OF APPLICABLE LAW"
            )}
          </p>
          <p className="paragraph-text">
            {t(
              "CLAUSE ON EXTENDED JURISDICTION AND CHOICE OF APPLICABLE LAW para 1"
            )}
            <br />
            {t(
              "CLAUSE ON EXTENDED JURISDICTION AND CHOICE OF APPLICABLE LAW para 2"
            )}
          </p>
        </div>
        {/* <div>
          <p className="fw-bold">{t("P. Adoption and editing")}</p>
          <p className="paragraph-text">{t("Adoption and editing para 1")}</p>
        </div> */}
        <div className="mt-5" style={{ lineHeight: "4px" }}>
          <p
            className="privacy-txt"
            onClick={() => navigate(`/avvertenze-legali`)}
          >
            {t("General Warnings")}
          </p>
          <p className="privacy-txt" onClick={() => navigate(`/condizioni`)}>
            {t("Conditions")}
          </p>
          <p className="privacy-txt" onClick={() => navigate(`/privacy`)}>
            {t("Privacy")}
          </p>
          <p className="privacy-txt" onClick={() => navigate(`/cookies`)}>
            {t("Cookies1")}
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Conditions;
