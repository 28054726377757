import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import {
  uploadDailyCSV,
  getAllFundsNames,
  uploadPDF,
  // getAllISIN,
  uploadFundDetailCSV,
  // uploadPolicyPDF,
  // GenerateFactsheet,
} from "../../apis/api-endpoint";
import useStore from "../../store";
import { toast } from "react-hot-toast";
import AddNewUser from "../AddNewUser";
import { ChangeEvent } from "react";
import { FundNames } from "../Types";
import GenerateFactsheets from "../GenerateFactsheets";
import UploadFactsheet from "../UploadFactsheets";
import RemunerationPolicy from "../RemunerationPolicy";

const UploadCSV = () => {
  const { hideLoader, displayLoader } = useStore();
  const [active, setAtive] = useState(0);
  const [dailySelectedFile, setDailySelectedFile] = useState<any>({});
  const [allfunds, setAllFunds] = useState<FundNames[]>([]);
  const [selectedFund, setSelectedFund] = useState<string>("Global");
  const [selectedPDF, setSelectedPDF] = useState<any>({});
  const [fundType, setFundType] = useState<string>("");
  const [csvFileError, setCsvFileError] = useState(false);
  const [pdfError, setPdfError] = useState(false);
  const [fundTypeError, setFundTypeError] = useState(false);
  const [selectedPolicy, setSelectedPolicy] = useState<any>({});
  const [policyError, setPolicyError] = useState(false);
  // const [fund, setFund] = useState<string>("");
  const [fundCode, setFundCode] = useState<string>("");
  const [prospettoSelectedFile, setProspettoSelectedFile] = useState<any>({});
  const [prospettoError, setProspettoError] = useState(false);
  const [selectLanguage, setSelectLanguage] = useState("");
  const [languageError, setLanguageError] = useState(false);
  const [prospettoLanguageSelect, setProspettoLanguageSelect] = useState("");
  const [prospettoLanguageError, setProspettoLanguageError] = useState(false);
  const [policyLanguageSelect, setPolicyLanguageSelect] = useState("");
  const [policyLanguageError, setPolicyLanguageError] = useState(false);

  useEffect(() => {
    const fundsName = async () => {
      displayLoader();
      const response = await getAllFundsNames();
      if (response?.status) {
        setAllFunds(response.data.fundDetails);
        hideLoader();
      } else if (!response?.status) {
        hideLoader();
      }
    };
    fundsName();
  }, [displayLoader, hideLoader]);

  useEffect(() => {
    if (dailySelectedFile.name !== "" || dailySelectedFile.name !== undefined) {
      setCsvFileError(false);
    }
    if (selectedPDF.name !== "" || selectedPDF.name !== undefined) {
      setPdfError(false);
    }
    if (fundType !== "") {
      setFundTypeError(false);
    }
    if (selectLanguage !== "") {
      setLanguageError(false);
    }
    if (selectedPolicy.name !== "" || selectedPolicy.name !== undefined) {
      setPolicyError(false);
    }
    if (
      prospettoSelectedFile.name !== "" ||
      prospettoSelectedFile.name !== undefined
    ) {
      setProspettoError(false);
    }

    if (prospettoLanguageSelect !== "") setProspettoLanguageError(false);
    if (policyLanguageSelect !== "") setPolicyLanguageError(false);
  }, [
    dailySelectedFile,
    selectedPDF,
    fundType,
    selectedPolicy,
    prospettoSelectedFile,
    selectLanguage,
    prospettoLanguageSelect,
    policyLanguageSelect,
  ]);

  const handleValidationCSV = () => {
    if (dailySelectedFile.name === "" || dailySelectedFile.name === undefined) {
      setCsvFileError(true);
    }
  };

  const handleValidationPDF = () => {
    if (selectedPDF.name === "" || selectedPDF.name === undefined) {
      setPdfError(true);
    } else if (fundType === "" && selectedFund !== "Global") {
      setFundTypeError(true);
    } else if (selectLanguage === "" && selectedFund === "Global") {
      setLanguageError(true);
    }
  };

  const handleValidationPolicy = () => {
    if (selectedPolicy.name === "" || selectedPolicy.name === undefined) {
      setPolicyError(true);
    } else if (policyLanguageSelect === "") {
      setPolicyLanguageError(true);
    }
  };

  const handleValidationProspetto = () => {
    if (
      prospettoSelectedFile.name === "" ||
      prospettoSelectedFile.name === undefined
    ) {
      setProspettoError(true);
    } else if (prospettoLanguageSelect === "") {
      setProspettoLanguageError(true);
    }
  };

  const handleActiveClass = (id: number, name: string, code: string) => {
    setAtive(id);
    setSelectedFund(name);
    setFundCode(code);
  };

  const handleDailyFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      setDailySelectedFile(file);
    }
  };
  const handleSelectPDF = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      setSelectedPDF(file);
    }
  };

  const handleSelectPolicy = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      setSelectedPolicy(file);
    }
  };

  const handleSelectProspetto = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      setProspettoSelectedFile(file);
    }
  };

  const handleDailyCSVUpload = async () => {
    handleValidationCSV();

    if (dailySelectedFile.name !== undefined) {
      displayLoader();
      const formData = new FormData();
      formData.append("file", dailySelectedFile);
      try {
        if (selectedFund === "Global") {
          const response = await uploadDailyCSV(formData);
          hideLoader();
          if (response?.status) {
            toast.success(response?.data.msg);
            const res = await getAllFundsNames();
            if (res?.status) {
              setAllFunds(res.data.fundDetails);
            }
            const fileInput = document.getElementById(
              "fileInput"
            ) as HTMLInputElement;
            if (fileInput) {
              fileInput.value = ""; // This clears the file selection
            }
            setDailySelectedFile({});
          } else {
            // Handle response status other than 200
          }
        } else {
          const response = await uploadFundDetailCSV(formData);
          console.log("DDD", response);
          hideLoader();
          if (response?.status) {
            toast.success(response?.data.msg);
            const res = await getAllFundsNames();
            if (res?.status) {
              setAllFunds(res.data.fundDetails);
            }
            const fileInput = document.getElementById(
              "fileInput"
            ) as HTMLInputElement;
            if (fileInput) {
              fileInput.value = ""; // This clears the file selection
            }
            setDailySelectedFile({});
          } else {
            // Handle response status other than 200
          }
        }
      } catch (error) {
        const err: any = error;
        hideLoader();
        toast.error(err?.response?.data?.errors[0]);
      }
    }
  };
  const handlePDFUpload = async () => {
    handleValidationPDF();

    if (
      selectedPDF.name !== undefined &&
      (fundType !== "" || selectLanguage !== "")
    ) {
      displayLoader();
      const formData = new FormData();
      formData.append("pdf", selectedPDF);
      if (selectedFund === "Global") {
        formData.append("pdfType", "past_performance");
        formData.append("language", selectLanguage);
      } else {
        formData.append("pdfType", fundType);
        formData.append("code", fundCode);
      }

      try {
        const response = await uploadPDF(formData);
        hideLoader();
        if (response?.status) {
          toast.success(response?.data.msg);
          setFundType("");
          const res = await getAllFundsNames();
          if (res?.status) {
            setAllFunds(res.data.fundDetails);
          }
          const fileInput = document.getElementById(
            "fileInputPdf"
          ) as HTMLInputElement;
          if (fileInput) {
            fileInput.value = "";
          }
          setSelectedPDF({});
          setSelectLanguage("");
        } else {
          // Handle response status other than 200
        }
      } catch (error) {
        hideLoader();
        // Handle the error here, for example:
        toast.error("An error occurred while uploading the PDF.");
      }
    }
  };

  const handlePolicyUpload = async () => {
    handleValidationPolicy();
    if (selectedPolicy.name !== undefined && policyLanguageSelect !== "") {
      displayLoader();
      const formData = new FormData();
      formData.append("pdf", selectedPolicy);
      formData.append("pdfType", "Policy_Pdf");
      formData.append("language", policyLanguageSelect);
      try {
        const response = await uploadPDF(formData);
        hideLoader();
        if (response?.status) {
          toast.success(response?.data.msg);
          setFundType("");
          const fileInput = document.getElementById(
            "fileInputPdfPolicy"
          ) as HTMLInputElement;
          if (fileInput) {
            fileInput.value = "";
          }
          setSelectedPolicy({});
          setPolicyLanguageSelect("");
        } else {
          // Handle response status other than 200
        }
      } catch (error) {
        hideLoader();
        // Handle the error here, for example:
        toast.error("An error occurred while uploading the PDF.");
      }
    }
  };

  const handlePropettoPDFUpload = async () => {
    handleValidationProspetto();
    if (
      prospettoSelectedFile.name !== undefined &&
      prospettoLanguageSelect !== ""
    ) {
      displayLoader();
      const formData = new FormData();
      formData.append("pdf", prospettoSelectedFile);
      formData.append("pdfType", "PROSPETTO");
      formData.append("language", prospettoLanguageSelect);

      try {
        const response = await uploadPDF(formData);
        hideLoader();
        if (response?.status) {
          toast.success(response?.data.msg);
          setFundType("");
          const res = await getAllFundsNames();
          if (res?.status) {
            setAllFunds(res.data.fundDetails);
          }
          const fileInput = document.getElementById(
            "fileProspetto"
          ) as HTMLInputElement;
          if (fileInput) {
            fileInput.value = "";
          }
          setProspettoSelectedFile({});
          setProspettoLanguageSelect("");
        } else {
          // Handle response status other than 200
        }
      } catch (error) {
        hideLoader();
        // Handle the error here, for example:
        toast.error("An error occurred while uploading the PDF.");
      }
    }
  };

  return (
    <div>
      <Navbar />
      <div className="admin">
        <div className="d-flex">
          <div className="side-bar">
            <p
              className={`${active === 0 && "active-box"}`}
              onClick={() => handleActiveClass(0, "Global", "")}
            >
              Global
            </p>
            {allfunds?.map((fund: FundNames, index: number) => {
              return (
                <p
                  key={index}
                  className={`${active === index + 1 && "active-box"}`}
                  onClick={() =>
                    handleActiveClass(index + 1, fund.description, fund.code)
                  }
                >
                  {fund?.description}
                </p>
              );
            })}
            <p
              className={`${active === 0.1 && "active-box"}`}
              onClick={() => handleActiveClass(0.1, "Users", "")}
            >
              Users
            </p>
          </div>
          <div className="admin-content">
            <p className="admin-main-text">{selectedFund}</p>

            {selectedFund === "Users" ? (
              <AddNewUser />
            ) : (
              <>
                <div className="file-box">
                  <p className="file-text">{`${
                    selectedFund === "Global"
                      ? "Upload Daily CSV"
                      : "Upload Monthly CSV"
                  }`}</p>
                  <div className="file">
                    <input
                      id="fileInput"
                      type="file"
                      accept=".xlsx"
                      onChange={handleDailyFileUpload}
                      className="file-input"
                    />

                    <button className="btn1" onClick={handleDailyCSVUpload}>
                      Upload CSV
                    </button>
                  </div>
                  {csvFileError && (
                    <p className="file-required-text">Please Choose File</p>
                  )}
                  <div className="file-name-list">
                    <p className="paragraph-text">{dailySelectedFile?.name}</p>
                  </div>
                </div>
                <div className="file-box">
                  <p className="file-text">{`${
                    selectedFund === "Global"
                      ? "Upload Commento Mensile dei Marcati"
                      : `Upload ${selectedFund} PDF`
                  }`}</p>
                  <div className="file">
                    <input
                      id="fileInputPdf"
                      type="file"
                      accept=".pdf"
                      onChange={handleSelectPDF}
                      className="file-input"
                    />
                    {selectedFund !== "Global" ? (
                      <select
                        id="fund"
                        name="funds"
                        value={fundType}
                        className="select-input"
                        onChange={(e) => setFundType(e.target.value)}
                      >
                        <option value="">Select Fund</option>
                        <option value="">Italian:</option>
                        <option value="KIID_CAPITALIZZAZIONE_EUR">
                          KIID CAPITALIZZAZIONE EUR
                        </option>
                        <option value="KIID_CAPITALIZZAZIONE_CHF">
                          KIID CAPITALIZZAZIONE CHF
                        </option>
                        <option value="KIID_DISTRIBUZIONE_CHF">
                          KIID DISTRIBUZIONE CHF
                        </option>
                        <option value="KIID_DISTRIBUZIONE_EUR">
                          KIID DISTRIBUZIONE EUR
                        </option>
                        <option value="">English:</option>
                        <option value="KIID_CAPITALIZATION_EUR">
                          KIID CAPITALIZATION EUR
                        </option>
                        <option value="KIID_CAPITALIZATION_CHF">
                          KIID CAPITALIZATION CHF
                        </option>
                        <option value="KIID_DISTRIBUTION_CHF">
                          KIID DISTRIBUTION CHF
                        </option>
                        <option value="KIID_DISTRIBUTION_EUR">
                          KIID DISTRIBUTION EUR
                        </option>
                        <option value="">German:</option>
                        <option value="KIID_THESAURIEREND_CHF">
                          KIID THESAURIEREND CHF
                        </option>
                        <option value="KIID_THESAURIEREND_EUR">
                          KIID THESAURIEREND EUR
                        </option>
                        <option value="KIID_AUSSCHÜTTEND_CHF">
                          KIID AUSSCHÜTTEND CHF
                        </option>
                        <option value="KIID_AUSSCHÜTTEND_EUR">
                          KIID AUSSCHÜTTEND EUR
                        </option>
                      </select>
                    ) : (
                      <select
                        id="fund"
                        name="funds"
                        value={selectLanguage}
                        className="select-input"
                        onChange={(e) => setSelectLanguage(e.target.value)}
                      >
                        <option value="">Select Language</option>
                        <option value="italian">Italian</option>
                        <option value="english">English</option>
                        <option value="german">German</option>
                      </select>
                    )}

                    <button className="btn1" onClick={handlePDFUpload}>
                      Upload PDF
                    </button>
                  </div>
                  {pdfError && (
                    <p className="file-required-text">Please Choose File</p>
                  )}

                  {fundTypeError && selectedFund !== "Global" && (
                    <p
                      className="file-required-text"
                      style={{ paddingLeft: "375px" }}
                    >
                      Required
                    </p>
                  )}
                  {languageError && selectedFund === "Global" && (
                    <p
                      className="file-required-text"
                      style={{ paddingLeft: "375px" }}
                    >
                      Required
                    </p>
                  )}
                  <div className="file-name-list">
                    <p className="paragraph-text">{selectedPDF?.name}</p>
                  </div>
                </div>
                {selectedFund === "Global" && (
                  <div className="file-box">
                    <p className="file-text">Upload Prospetto</p>
                    <div className="file">
                      <div>
                        <input
                          id="fileProspetto"
                          type="file"
                          accept=".pdf"
                          onChange={handleSelectProspetto}
                          className="file-input"
                        />
                        {prospettoError && (
                          <p className="file-required-text ps-4">
                            Please Choose File
                          </p>
                        )}
                      </div>
                      <div>
                        <select
                          id="fund"
                          name="funds"
                          value={prospettoLanguageSelect}
                          className="select-input"
                          onChange={(e) =>
                            setProspettoLanguageSelect(e.target.value)
                          }
                        >
                          <option value="">Select Language</option>
                          <option value="italian">Italian</option>
                          <option value="english">English</option>
                          <option value="german">German</option>
                        </select>
                        {prospettoLanguageError && (
                          <p className="file-required-text ps-4">Required</p>
                        )}
                      </div>
                      <div>
                        <button
                          className="btn1 mt-0"
                          onClick={handlePropettoPDFUpload}
                        >
                          Upload Prospetto
                        </button>
                      </div>
                    </div>

                    <div className="file-name-list">
                      <p className="paragraph-text">
                        {prospettoSelectedFile?.name}
                      </p>
                    </div>
                  </div>
                )}
                {selectedFund === "Global" && (
                  <div className="file-box">
                    <p className="file-text">Upload ESG Policy</p>
                    <div className="file">
                      <div>
                        <input
                          id="fileInputPdfPolicy"
                          type="file"
                          accept=".pdf"
                          onChange={handleSelectPolicy}
                          className="file-input"
                        />
                        {policyError && (
                          <p className="file-required-text ps-4">
                            Please Choose File
                          </p>
                        )}
                      </div>
                      <div>
                        <select
                          id="fund"
                          name="funds"
                          value={policyLanguageSelect}
                          className="select-input"
                          onChange={(e) =>
                            setPolicyLanguageSelect(e.target.value)
                          }
                        >
                          <option value="">Select Language</option>
                          <option value="italian">Italian</option>
                          <option value="english">English</option>
                          <option value="german">German</option>
                        </select>
                        {policyLanguageError && (
                          <p className="file-required-text ps-4">Required</p>
                        )}
                      </div>
                      <div>
                        <button
                          className="btn1 mt-0"
                          onClick={handlePolicyUpload}
                        >
                          ESG Policy
                        </button>
                      </div>
                    </div>

                    <div className="file-name-list">
                      <p className="paragraph-text">{selectedPolicy?.name}</p>
                    </div>
                  </div>
                )}
                {selectedFund === "Global" && <RemunerationPolicy />}

                {selectedFund !== "Global" && (
                  <div className="file-box">
                    <p className="file-text">Generate Factsheet</p>
                    <GenerateFactsheets fundcode={fundCode} />
                  </div>
                )}
                {selectedFund !== "Global" && (
                  <div className="file-box">
                    <p className="file-text">Upload Factsheet</p>
                    <UploadFactsheet
                      fundcode={fundCode}
                      selectedFund={selectedFund}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadCSV;
