import React, { useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const LegalNotice = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Navbar />

      <div className="privacy-main">
        <p className="red-text">{t("Legal Notice detail")}</p>
        {/* {i18n.language === "it" && ( */}
        <p className="paragraph-text">{t("Legal Notice para 5")}</p>
        {/* )} */}
        {/* {i18n.language === "it" && ( */}
        <p className="paragraph-text">{t("Legal Notice para 6")}</p>
        {/* )} */}
        {/* {i18n.language === "it" && ( */}
        <p className="paragraph-text">{t("Legal Notice para 9")}</p>
        {/* )} */}
        {/* {i18n.language === "it" && ( */}
        <p className="paragraph-text">{t("Legal Notice para 7")}</p>
        {/* )} */}
        {/* {i18n.language === "it" && ( */}
        <p className="paragraph-text">{t("Legal Notice para 8")}</p>
        {/* )} */}
        <p className="paragraph-text">
          {t("Legal Notice para 1")}{" "}
          <a href="https://www.axionsicav.com" target="__blank">
            www.axionsicav.com
          </a>{" "}
          {t("Legal Notice para 2")} <br />
          {/* {t("Legal Notice para 3")} */}
        </p>
        {i18n.language === "en" && (
          <p className="paragraph-text">
            {t("Legal Notice para 3")}{" "}
            <a href="https://www.axionsicav.com" target="__blank">
              www.axionsicav.com
            </a>{" "}
            {t("Legal Notice para 4")} <br />
            {/* {t("Legal Notice para 3")} */}
          </p>
        )}
        <div className="mt-5" style={{ lineHeight: "4px" }}>
          <p
            className="privacy-txt"
            onClick={() => navigate(`/avvertenze-legali`)}
          >
            {t("General Warnings")}
          </p>
          <p className="privacy-txt" onClick={() => navigate(`/condizioni`)}>
            {t("Conditions")}
          </p>
          <p className="privacy-txt" onClick={() => navigate(`/privacy`)}>
            {t("Privacy")}
          </p>
          <p className="privacy-txt" onClick={() => navigate(`/cookies`)}>
            {t("Cookies1")}
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default LegalNotice;
