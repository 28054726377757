import React, { useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Disclaim = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [confirm, setConfirm] = useState<boolean>(false);
  const [isConfirm, setIsConfirm] = useState<boolean>(false);

  const handleNavigate = () => {
    navigate(`/optimization`);
  };

  const handleConfirm = () => {
    setIsConfirm(true);
  };
  const handleConfirmation = (confirm: string) => {
    if (confirm === "Yes") {
      setConfirm(true);
    } else {
      navigate(`/`);
    }
  };
  return (
    <div>
      <Navbar />
      <div className="background-container">
        <div className="text-wrap">
          <p className="benvenuti">
            {t("Welcome in ASB")} <br /> {t("Axion SICAV!")}
          </p>
          <p className="benvenutii">{t("Benvenuti in ASB Axion SICAV!")}</p>

          <h6>
            {t("To access the portal we kindly invite you")} <br />{" "}
            {t("to read the legal disclaimer and to accept")}
            <br /> {t("the user's conditions")}
          </h6>
        </div>
        <div className="confirm-box">
          <div className="confirm-box-blur d-flex align-items-center justify-content-center">
            <div className="confirm-box-content">
              {isConfirm && (
                <p>
                  {t(
                    "We invite you to confirm that you have read, understood and taken note of the contents of the disclaimer and"
                  )}
                  <br />
                  {t(
                    "that you are not a US Person as defined in the prospectus of the Fund."
                  )}
                </p>
              )}

              {!confirm && (
                <p>
                  {t(
                    "You confirm that you are an active investor resident in Switzerland or Luxembourg."
                  )}
                </p>
              )}
              {confirm && !isConfirm && <p>{t("I am an investor")}</p>}

              <div className="confirm-box-btns ">
                {isConfirm && (
                  <button className="btn2" onClick={handleNavigate}>
                    {t("I CONFIRM")}
                  </button>
                )}
                {!confirm && (
                  <button
                    className="btn3"
                    onClick={() => handleConfirmation("Yes")}
                  >
                    {t("YES")}
                  </button>
                )}
                {!confirm && (
                  <button
                    className="btn2"
                    onClick={() => handleConfirmation("No")}
                  >
                    NO
                  </button>
                )}

                {confirm && !isConfirm && (
                  <button className="btn2" onClick={handleConfirm}>
                    {t("PRIVATE")}
                  </button>
                )}
                {confirm && !isConfirm && (
                  <button className="btn3" onClick={handleConfirm}>
                    {t("PROFESSIONAL")}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="disclaim">
        <div className="avvertenze">
          <p className="red-text">{t("DISCLAIMER")}</p>
          <p className="paragraph-text">{t("DISCLAIMER PARAGRAPH")}</p>
        </div>
        <div className="disclaimer">
          <p className="red-text cntr-txt">{t("LEGAL DISCLAIMER")}</p>
          <p className="paragraph-text">{t("LEGAL DISCLAIMER PARAGRAPH 1")}</p>
          <p className="paragraph-text">{t("LEGAL DISCLAIMER PARAGRAPH 2")}</p>
          <p className="paragraph-text">{t("LEGAL DISCLAIMER PARAGRAPH 3")}</p>
          <p className="paragraph-text">{t("LEGAL DISCLAIMER PARAGRAPH 4")}</p>
          {i18n.language === "it" && (
            <p className="paragraph-text">
              {t("LEGAL DISCLAIMER PARAGRAPH 5")}
            </p>
          )}
        </div>
        <div className="hardware">
          <div className="row">
            <div className="col-3">
              <p className="red-text"> {t("HARDWARE REQUIREMENT")}</p>
              <p className="paragraph-text">Personal Computer</p>
              <p className="paragraph-text">
                {t("Modem analogico possibilmente")}
                <br /> {t("ISDN modem or ADSL modem")}
              </p>
              <p className="paragraph-text">
                {t("The website is optimized for viewing")}
                <br />
                {t("on screen with a resolution of 1920 x")}
                <br />
                {t("1080 px")}
              </p>
            </div>
            <div className="col-3">
              <p className="red-text">{t("RECOMMENDED BROWSER")}</p>
              <p className="paragraph-text">
                {t("Microsoft Edge 44.17763 or more")}
                <br />
                {t("(EdgeHTLM 18. or more)")}
              </p>
              <p className="paragraph-text">{t("Chrome 72.0.3626 or more")}</p>
              <p className="paragraph-text">{t("Safari 12.0.02 or more")}</p>
              <p className="paragraph-text">{t("Firefox 65.0.1 or more")}</p>
              <p className="paragraph-text">
                {t("The browser must also be enabled to")}
                <br />
                {t("accept cookies.")}
              </p>
              <p className="paragraph-text">
                {t("Normally, browsers update")}
                <br />
                {t("automatically or indicate the")}
                <br />
                {t("necessary updates. If this is not the")}
                <br />
                {t("case it is also possible to do updates")}
                <br />
                {t("manually.")}
              </p>
              <p className="paragraph-text">
                {t("In the follow links you could find the")}
                <br />
                {t("instruction and/or the components")}
                <br />
                {t("related to the different browser")}
              </p>
              <p className="paragraph-text" style={{ color: "#ff172e" }}>
                Microsoft Edge
              </p>
              <p className="paragraph-text" style={{ color: "#ff172e" }}>
                Chrome
              </p>
              <p className="paragraph-text" style={{ color: "#ff172e" }}>
                Safari
              </p>
              <p className="paragraph-text" style={{ color: "#ff172e" }}>
                Firefox
              </p>
            </div>
            <div className="col-3">
              <p className="red-text">{t("OPERATING SYSTEM")}</p>
              <p className="paragraph-text">
                {t("Windows 7 or more. We inform you")}
                <br />
                {t("that the manufacturer Microsoft does")}
                <br />
                {t("not provide support for Windows 7")}
                <br />
                {t("anymore. This means that software")}
                <br />
                {t("and security updates are no longer")}
                <br />
                {t("available. We invite you to use")}
                <br />
                {t("Windows 10. Windows Explorer is not")}
                <br />
                {t("longer supported.")}
              </p>
              <p className="paragraph-text">
                {t("Mac OSX 10.14.3 (Mojave) or more")}
              </p>
              <p className="paragraph-text">{t("Linux Kernel 4.20 or more")}</p>
              <p className="paragraph-text">
                {t("Operating systems update")}
                <br />
                {t("automatically or report updates.")}
                <br />
                {t("For any questions, please contact")}
                <br />
                {t("contact your dealer or IT")}
                <br />
                {t("specialist.")}
              </p>
              <p className="paragraph-text">
                {t("Adobe Acrobat Reader (14 or more)")}
                <br />
                {t("and Java (SE 8 Update 111 or more)")}
                <br />
                {t("software installations are")}
                <br />
                {t("recommended.")}
              </p>
            </div>
            <div className="col-3">
              <p className="red-text">{t("OTHER REQUIREMENTS")}</p>
              <p className="paragraph-text">
                {t("Internet contract with any Internet-Provider")}
              </p>
              <p className="paragraph-text">
                {t(
                  "The transmitted data are encrypted with a complex electronic procedure. (SSL standard code)."
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Disclaim;
