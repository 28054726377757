import React from "react";
import { useTranslation } from "react-i18next";

const RiskIndex = ({
  riskIndex,
  fundOverViewEng,
  fundOverViewIt,
}: {
  riskIndex: string;
  fundOverViewEng: string;
  fundOverViewIt: string;
}) => {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <p className="obective-txt" style={{ marginBottom: "50px" }}>
        {t("Fund overview")}
      </p>
      <p className="obective-txt" style={{ marginBottom: "50px" }}>
        {i18n.language === "en" ? fundOverViewEng : fundOverViewIt}
      </p>
      <p className="obective-txt" style={{ marginBottom: "30px" }}>
        {t("Risk and Reward Profile")}
      </p>
      <div className="paging">
        <p className="basso-txt">{t("Low")}</p>
        <div
          className={`${
            riskIndex === "1" ? "square-box-red" : "square-box"
          } d-flex justify-content-center align-items-center`}
        >
          <p>1</p>
        </div>
        <div
          className={`${
            riskIndex === "2" ? "square-box-red" : "square-box"
          } d-flex justify-content-center align-items-center`}
        >
          <p>2</p>
        </div>
        <div
          className={`${
            riskIndex === "3" ? "square-box-red" : "square-box"
          } d-flex justify-content-center align-items-center`}
        >
          <p>3</p>
        </div>
        <div
          className={`${
            riskIndex === "4" ? "square-box-red" : "square-box"
          } d-flex justify-content-center align-items-center`}
        >
          <p>4</p>
        </div>

        <div
          className={`${
            riskIndex === "5" ? "square-box-red" : "square-box"
          } d-flex justify-content-center align-items-center`}
        >
          <p>5</p>
        </div>
        <div
          className={`${
            riskIndex === "6" ? "square-box-red" : "square-box"
          } d-flex justify-content-center align-items-center`}
        >
          <p>6</p>
        </div>
        <div
          className={`${
            riskIndex === "7" ? "square-box-red" : "square-box"
          } d-flex justify-content-center align-items-center`}
        >
          <p>7</p>
        </div>
        <p className="basso-txt" style={{ rotate: "90deg" }}>
          {t("High")}
        </p>
      </div>
      <p className="obective-txt">
        {t(
          "Information on SRRI and the main risk indicators are available in the Risk and Reward Profile section of the fund KIID."
        )}
      </p>
    </div>
  );
};

export default RiskIndex;
