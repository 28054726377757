import React from "react";
import Navbar from "../Navbar";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { generatOTP } from "../../apis/api-endpoint";
import useStore from "../../store";
import { toast } from "react-hot-toast";

const SendOTP = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { userData, hideLoader, displayLoader } = useStore();

  const handleSendOTP = async () => {
    displayLoader();
    const response: any = await generatOTP({ email: userData?.email });
    if (response instanceof Error) {
      //@ts-ignore
      toast.error(response?.response?.data?.errors);
      hideLoader();
    } else if (response?.data?.status) {
      // toast.success(response?.data?.msg);
      navigate("/verify-otp");
      hideLoader();
    } else {
      hideLoader();
    }
  };
  return (
    <div>
      <Navbar />
      <div className="background-container">
        <div className="text-wrap">
          <p className="benvenuti">
            {t("Welcome in ASB")} <br /> {t("Axion SICAV!")}
          </p>
          <p className="benvenutii">{t("Benvenuti in ASB Axion SICAV!")}</p>

          <h6>
            {t("To access the portal we kindly invite you")} <br />{" "}
            {t("to read the legal disclaimer and to accept")}
            <br /> {t("the user's conditions")}
          </h6>
        </div>
        <div className="login-box">
          <div className="login-box-blur d-flex align-items-center justify-content-center">
            <div className="login-box-content">
              <p className="text-center" style={{ fontSize: "16px" }}>
                Email for OTP
              </p>
              <div>
                <div className="d-flex justify-content-center">
                  <input
                    type="text"
                    placeholder="Enter email"
                    value={userData?.email}
                    disabled
                  />
                </div>
              </div>
              <div className="confirm-box-btns mt-4">
                <button className="btn2" onClick={handleSendOTP}>
                  Send OTP
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendOTP;
