import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Footer from "../Footer";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useStore from "../../store";
import { getPdfLinks } from "../../apis/api-endpoint";
import { PdfLink } from "../Types";
import Logo1 from "../../assets/images/SWISS_SUSTAINABLE_FINANCE.png";
import Logo2 from "../../assets/images/ASSET_MANAGEMENT.jpg";

const Home = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { displayLoader, hideLoader } = useStore();
  const [chiArrow, setChiArrow] = useState(false);
  const [mssionArrow, setMissionArrow] = useState(false);
  const [orgArrow, setOrgArrow] = useState(false);
  const [investArrow, setIvestArrow] = useState(false);
  const [pdfLinks, setPdfLinks] = useState<PdfLink[]>([]);

  useEffect(() => {
    const handleFunds = async () => {
      displayLoader();
      try {
        const response = await getPdfLinks({ pdfType: "Policy_Pdf" });
        if (response?.status) {
          setPdfLinks(response?.data?.pdfLinks);
          hideLoader();
        } else {
          hideLoader();
          // Handle the case when response is undefined or falsy
          // toast.error("Failed to fetch funds data.");
        }
      } catch (error) {
        hideLoader();
        // Handle the error here, for example:
        // toast.error("An error occurred while fetching funds data.");
      }
    };

    handleFunds();
  }, [hideLoader, displayLoader]);

  const handleNavigate = () => {
    navigate(`/sicavdisclaim`);
  };

  const handleOPenLink = () => {
    const italianPolicyPdf = pdfLinks?.find(
      (pdf) => pdf.language === "italian" && pdf.type === "Policy_Pdf"
    );
    const englishPolicyPdf = pdfLinks?.find(
      (pdf) => pdf.language === "english" && pdf.type === "Policy_Pdf"
    );

    if (i18n.language === "it") {
      window.open(`${italianPolicyPdf?.url}`, "_blank", "noopener, noreferrer");
    } else {
      window.open(`${englishPolicyPdf?.url}`, "_blank", "noopener, noreferrer");
    }
  };

  const handleOPenRemunerationLink = () => {
    const italianRemunerationPolicyPdf = pdfLinks?.find(
      (pdf) => pdf.language === "italian" && pdf.type === "Remuneration_Policy"
    );
    const englishRemunerationPolicyPdf = pdfLinks?.find(
      (pdf) => pdf.language === "english" && pdf.type === "Remuneration_Policy"
    );

    if (i18n.language === "it") {
      window.open(
        `${italianRemunerationPolicyPdf?.url}`,
        "_blank",
        "noopener, noreferrer"
      );
    } else {
      window.open(
        `${englishRemunerationPolicyPdf?.url}`,
        "_blank",
        "noopener, noreferrer"
      );
    }
  };

  return (
    <div>
      <Navbar />
      <div
        className="background-container-home"
        // style={{
        //   backgroundImage: `url(${Background})`,
        //   backgroundPosition: "top",
        // }}
      >
        <div className="text-wrap">
          <h6>{t("headerText")}</h6>
          <p className="benvenutii">{t("Home page banner text")}</p>
          <p className="benvenuti">{t("Home page banner text")}</p>

          <button className="btn1" onClick={handleNavigate}>
            {t("ACCSESS TO FUNDS")}
            <span>
              <IoIosArrowForward />
            </span>
          </button>
        </div>
      </div>
      <div id="chisiamo" className="chi-siamo">
        <div
          className={`${
            chiArrow ? "blur" : "half-blur"
          } d-flex justify-content-center align-items-center`}
        >
          <div className="chi-siamo-container">
            <p className="red-text">{t("WHO WE ARE")}</p>
            <p className="paragraph-text">
              {t("CHI SIAMO PARAGRAPH 1")}
              <p className={`${chiArrow ? "" : "half-text"}`}>
                {t("CHI SIAMO PARAGRAPH 1.1")}
              </p>
            </p>
            <p className={`paragraph-text ${chiArrow ? "" : "blk"}`}>
              {t("CHI SIAMO PARAGRAPH 2")}
            </p>
            <p
              className={`paragraph-text ${chiArrow ? "" : "blk"}`}
              style={{ color: "#ff0019" }}
            >
              {t("AXION KEY FIGURES")}
              <span style={{ color: "#000" }}>(31.12.2023)</span>
              <ul style={{ color: "#000" }}>
                <li>{t("100% owned by BancaStato")}</li>
                <li>{t("A Swiss success story since 1998")}</li>
                <li>{t("Focused on high-quality Private Banking")}</li>
                <li>
                  {t("Independent management of Luxembourg-based SICAV funds")}
                </li>
                <li>{t("Balance sheet total: CHF 1.24 billion")}</li>
                <li>{t("Core Tier 1 (CET1) : 27.2%")}</li>
                <li>{t("74 employees")}</li>
              </ul>
            </p>
            <p className={`paragraph-text ${chiArrow ? "" : "blk"}`}>
              {t("CHI SIAMO PARAGRAPH 3")}
            </p>

            <p
              className={`paragraph-text ${chiArrow ? "" : "blk"}`}
              style={{ color: "#ff0019" }}
            >
              {t("BANCASTATO KEY FIGURES")}
              <span style={{ color: "#000" }}>(31.12.2023)</span>
              <ul style={{ color: "#000" }}>
                <li>
                  {t(
                    "Owned by the State and the Republic of the Canton of Ticino"
                  )}
                </li>
                <li>{t("Guaranteed by the State")}</li>
                <li>{t("Bank created in 1915")}</li>
                <li>{t("Balance sheet total: CHF 17.1 billion")}</li>
                <li>{t("Real estate mortgages : CHF 11.8 billion")}</li>
                <li>{t("Core Tier 1 (CET1): 15.3%")}</li>
                <li>{t("487 employees, 4 branches, 16 agencies")}</li>
                <li>{t("Moody’s rating Canton Ticino: Aa2")}</li>
              </ul>
            </p>
            <p className={`paragraph-text ${chiArrow ? "" : "blk"}`}>
              Member of:
            </p>
            <img
              src={Logo1}
              alt="logo"
              height={120}
              width={250}
              className={`${chiArrow ? "" : "blk"}`}
            />
            <img
              src={Logo2}
              alt="logo"
              height={120}
              width={250}
              className={`mrgn-left ${chiArrow ? "" : "blk"}`}
            />

            <div className="arrow-btn d-flex justify-content-center">
              {chiArrow ? (
                <i onClick={() => setChiArrow(false)}>
                  <IoIosArrowForward />
                </i>
              ) : (
                <i onClick={() => setChiArrow(true)}>
                  <IoIosArrowBack />
                </i>
              )}
            </div>
          </div>
        </div>
      </div>
      <div id="mission" className="mission">
        <div
          className={`${
            mssionArrow ? "blur" : "half-blur"
          } d-flex justify-content-center align-items-center`}
        >
          <div className="chi-siamo-container">
            <p className="red-text">MISSION</p>
            <p className="paragraph-text">{t("MISSION SHORT PARAGRAPH")}</p>
            <p className={`paragraph-text ${mssionArrow ? "" : "half-text"}`}>
              {t("MISSION PARAGRAPH")}
            </p>
            <div className="arrow-btn d-flex justify-content-center">
              {mssionArrow ? (
                <i onClick={() => setMissionArrow(false)}>
                  <IoIosArrowBack />
                </i>
              ) : (
                <i onClick={() => setMissionArrow(true)}>
                  <IoIosArrowForward />
                </i>
              )}
            </div>
          </div>
        </div>
      </div>
      <div id="organizzazione" className="organizzazione">
        <div
          className={`${
            orgArrow ? "blur" : "half-blur"
          } d-flex align-items-center`}
        >
          <div className="chi-siamo-container">
            <p className="red-text">{t("ORGANIZATION")}</p>
            <p className="red-text" style={{ color: "#000" }}>
              ASB Axion SICAV
              <p className="paragraph-text">
                {t("Registered office:")}
                <br />
                ASB Axion SICAV, 5 Allee Scheffer, L - 2520 Luxembourg
                <br />
                {t("Board of Director:")}
                <br />
                . Giampiero Marangio
                <br />
                . Walter Lisetto
                <br />
                . Alberto Moscato
                <br />
                . Enrico Turchi
                <br />
                . Gabriele Zanzi
                <br />
              </p>
            </p>
            <p className="red-text" style={{ color: "#000" }}>
              {t("Depositary & Paying Agent")}
              <p className="paragraph-text">
                Société Générale Luxembourg, 11 Avenue Emilie Reuter, L - 2420
                Luxembourg
              </p>
            </p>
            <p
              className={`red-text ${orgArrow ? "" : "blk"}`}
              style={{ color: "#000" }}
            >
              {t("Investment Manager")}
              <p className="paragraph-text">
                Axion SWISS Bank SA, Viale S. Franscini 22, CH – 6901 Lugano
              </p>
            </p>

            <p
              className={`red-text ${orgArrow ? "" : "blk"}`}
              style={{ color: "#000" }}
            >
              {t("Distributor")}
              <p className="paragraph-text">
                Axion SWISS Bank SA, Viale S. Franscini 22, CH – 6901 Lugano
                <br />
                BancaStato, Viale H. Guisan 5, CH-6500 Bellinzona
              </p>
            </p>
            <p
              className={`red-text ${orgArrow ? "" : "blk"}`}
              style={{ color: "#000" }}
            >
              {t("Auditor")}
              <p className="paragraph-text">
                PricewaterhouseCoopers, Société coopérative, 2, rue Gerhard
                Mercator, B.P. 1443, L-1014 Luxembourg, Grand Duchy of
                Luxembourg
              </p>
            </p>
            <p
              className={`red-text ${orgArrow ? "" : "blk"}`}
              style={{ color: "#000" }}
            >
              Management Company
              <p className="paragraph-text">
                Amundi Luxembourg SA, 5, Allée Scheffer, L-2520 Luxembourg,
                Grand-Duchy of Luxembourg
              </p>
            </p>
            <p
              className={`red-text ${orgArrow ? "" : "blk"}`}
              style={{ color: "#000" }}
            >
              {t("Swiss Representative")}
              <p className="paragraph-text">
                CACEIS (Switzerland) SA, Route de Signy, 35, CH-1260 Nyon / VD,
                Suisse
              </p>
            </p>
            <p
              className={`red-text ${orgArrow ? "" : "blk"}`}
              style={{ color: "#000" }}
            >
              {t("Legal Advisor")}
              <p className="paragraph-text">
                Arendt & Medernach S.A., 41°, avenue J.F. Kennedy, L - 2082
                Luxembourg
              </p>
            </p>

            <div className="arrow-btn d-flex justify-content-center">
              {orgArrow ? (
                <i onClick={() => setOrgArrow(false)}>
                  <IoIosArrowForward />
                </i>
              ) : (
                <i onClick={() => setOrgArrow(true)}>
                  <IoIosArrowBack />
                </i>
              )}
            </div>
          </div>
        </div>
      </div>
      <div id="sustainable" className="sustainable">
        <div
          className={`${
            investArrow ? "blur" : "half-blur"
          } d-flex justify-content-center align-items-center`}
        >
          <div className="chi-siamo-container">
            <p className="red-text">{t("SUSTAINABLE INVESTMENTS")}</p>
            <p>
              {t(
                "OVERVIEW OF THE MANAGEMENT COMPANY'S SUSTAINABLE INVESTMENT POLICY"
              )}
            </p>
            <p className={`paragraph-text ${investArrow ? "" : "half-text"}`}>
              {t("SUSTAINABLE INVESTMENTS PARAGRAPH 1")}
            </p>
            <p className={`paragraph-text ${investArrow ? "" : "blk"}`}>
              {t("SUSTAINABLE INVESTMENTS PARAGRAPH 2")}
            </p>
            <p className={`paragraph-text ${investArrow ? "" : "blk"}`}>
              {t("SUSTAINABLE INVESTMENTS PARAGRAPH 5")}
            </p>
            <p className={`paragraph-text ${investArrow ? "" : "blk"}`}>
              {t("SUSTAINABLE INVESTMENTS PARAGRAPH 6")}
            </p>
            <p className={`paragraph-text ${investArrow ? "" : "blk"}`}>
              {t("SUSTAINABLE INVESTMENTS PARAGRAPH 3")}
            </p>
            <p className={`paragraph-text ${investArrow ? "" : "blk"}`}>
              {t("SUSTAINABLE INVESTMENTS PARAGRAPH 4")}
            </p>
            <p className={`paragraph-text ${investArrow ? "" : "blk"}`}>
              {t("SUSTAINABLE INVESTMENTS PARAGRAPH 7")}
            </p>

            <button
              className={`btn1 mt-0 ${investArrow ? "" : "blk"}`}
              onClick={handleOPenLink}
            >
              ESG Policy
            </button>

            <button
              className={`btn1 mrgn-left ${investArrow ? "" : "blk"}`}
              onClick={handleOPenRemunerationLink}
            >
              Remuneration Policy
            </button>

            <div className="arrow-btn d-flex justify-content-center">
              {investArrow ? (
                <i onClick={() => setIvestArrow(false)}>
                  <IoIosArrowBack />
                </i>
              ) : (
                <i onClick={() => setIvestArrow(true)}>
                  <IoIosArrowForward />
                </i>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
