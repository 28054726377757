import React from "react";
import { useTranslation } from "react-i18next";
import useStore from "../../store";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";

const SectorAreaBarChart = ({ filteredSectorAreaData, ccy }: any) => {
  const { t, i18n } = useTranslation();
  const { fundName } = useStore();
  const splitFundName = fundName?.split("-");
  const CustomBar = (props: any) => {
    const { x, y, width, height } = props;
    const radius = 10; // Border radius value
    return (
      <path
        d={`M${x},${y + height} 
                   L${x},${y + radius} 
                   Q${x},${y} ${x + radius},${y} 
                   L${x + width - radius},${y} 
                   Q${x + width},${y} ${x + width},${y + radius} 
                   L${x + width},${y + height} Z`}
        fill={props.fill}
      />
    );
  };
  return (
    <>
      <p className="andamento-txt">
        {t("Sector exposure (%) of ASB Axion SICAV")}
      </p>
      <p className="kermata-txt">{`${splitFundName?.[1]} ${ccy} class`}</p>
      <div className="d-flex justify-content-center chart">
        <BarChart width={1200} height={600} data={filteredSectorAreaData}>
          <CartesianGrid />
          <XAxis
            dataKey="label"
            tick={{ fontSize: 12 }}
            interval={0}
            tickMargin={10}
          />
          <YAxis tick={{ fontSize: 18 }} />
          <Tooltip />
          <Bar dataKey="value" fill="red" barSize={90} shape={<CustomBar />} />
        </BarChart>
      </div>
      <p className="passate-txt lst-txt">
        {t("Sector exposure detail line 1")}
        <br />
        {t("Sector exposure detail line 2")} <br />{" "}
        {i18n.language === "it" && t("Sector exposure detail line 3")}
      </p>
    </>
  );
};

export default SectorAreaBarChart;
