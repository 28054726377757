import React from "react";
import { useTranslation } from "react-i18next";
import useStore from "../../store";
import moment from "moment";
import "moment/locale/it";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LineChart,
  Line,
  Dot,
} from "recharts";

const PerformanceLineChart = ({ linChartData }: any) => {
  const { t, i18n } = useTranslation();
  const { fundName } = useStore();

  const lastElementDate = linChartData[linChartData?.length - 1]?.date;
  const formattedDate = moment(lastElementDate, "M/D/YY").format("DD MMM YY");
  const date = moment(lastElementDate);
  const itDate = date.locale("it").format("DD MMM YY");

  const modifiedData: any = linChartData?.map((item: any) => ({
    ...item,
    date: item?.date.split("/")[0] + "/" + item?.date.split("/")[2], // Extract month and year
    integerValue: parseInt(item?.value), // Convert value to integer
  }));

  const greatestValue = modifiedData?.reduce(
    (max: any, item: any) =>
      item?.integerValue > max ? item?.integerValue : max,
    modifiedData[0]?.integerValue
  );

  const lowestValue = modifiedData?.reduce(
    (min: any, item: any) =>
      item?.integerValue < min ? item?.integerValue : min,
    modifiedData[0]?.integerValue
  );

  const numberOfTicks = 8;
  const tickInterval = Math.ceil(
    (greatestValue + 1 - (lowestValue - 1)) / (numberOfTicks - 1)
  ); // Ensuring no decimals
  const ticks = Array.from({ length: numberOfTicks }, (v, i) =>
    Math.round(lowestValue + i * tickInterval)
  );

  const CustomDot = (props: any) => {
    const { cx, cy, stroke } = props;
    return (
      <Dot cx={cx} cy={cy} r={4} strokeWidth={1} stroke={stroke} fill="red" />
    );
  };
  return (
    <>
      <p className="andamento-txt">{`${t("Performance of")} ${fundName}`}</p>
      <p className="kermata-txt">
        {` ${t("Performance is shown net of fees as at 29.12.23")} ${
          i18n.language === "en" ? formattedDate : itDate
        }`}
      </p>
      <div className="d-flex justify-content-center chart">
        <LineChart width={1200} height={600} data={modifiedData}>
          <CartesianGrid horizontal={true} vertical={false} />
          <XAxis dataKey="date" tick={{ fontSize: 12 }} />
          <YAxis
            tick={{ fontSize: 12 }}
            domain={[lowestValue - 1, greatestValue + 2]}
            dataKey="integerValue"
            ticks={ticks}
          />
          <Tooltip />

          <Line
            type="monotone"
            dataKey="value"
            stroke="red"
            dot={<CustomDot />}
          />
        </LineChart>
      </div>
      <p className="passate-txt">
        {t(
          "Past performance does not guarantee and is not indicative of future results."
        )}
      </p>
    </>
  );
};

export default PerformanceLineChart;
