import React from "react";
import { useTranslation } from "react-i18next";
import BancaStatoLogo from "../../assets/images/BancaStato-logo.png";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="footer d-flex align-items-center">
      <div className="footer-container">
        <div>
          <p>
            ASB Axion SICAV <br />
            5, Allée Scheffer
            <br />
            L-2520 Luxembourg
            <br /> Grand-Duchy of Luxembourg
            <br />
            info@axionsicav.com
          </p>
        </div>
        <div>
          <div className="d-flex justify-content-end">
            <img src={BancaStatoLogo} alt="logo" />
          </div>

          <div className="d-flex mt-2">
            <p className="p-2">ASB Axion SICAV</p>
            <div className="text-divider"></div>
            <p
              className="p-2 "
              style={{ fontWeight: 600, cursor: "pointer" }}
              onClick={() => navigate(`/avvertenze-legali`)}
            >
              {t("Legal Notice")}
            </p>
            <div className="text-divider"></div>
            <p
              className="p-2"
              style={{ fontWeight: 600, cursor: "pointer" }}
              onClick={() => navigate(`/condizioni`)}
            >
              {t("Terms of Use")}
            </p>
            <div className="text-divider"></div>
            <p
              className="p-2"
              style={{ fontWeight: 600, cursor: "pointer" }}
              onClick={() => navigate(`/privacy`)}
            >
              {t("Privacy")}
            </p>
            <div className="text-divider"></div>
            <p
              className="p-2"
              style={{ fontWeight: 600, cursor: "pointer" }}
              onClick={() => navigate(`/cookies`)}
            >
              {t("Cookies")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
