import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
// import Footer from "../Footer";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { signin } from "../../apis/api-endpoint";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import useStore from "../../store";
import { toast } from "react-hot-toast";

const Login = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setData, hideLoader, displayLoader } = useStore();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [eyeOpen, setEyeOpen] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  useEffect(() => {
    if (email !== "") {
      setEmailError(false);
    }
    if (password !== "") {
      setPasswordError(false);
    }
  }, [email, password]);

  const handleNavigate = () => {
    navigate(`/forgot-password`);
  };

  const handleError = () => {
    if (email === "") {
      setEmailError(true);
    } else if (password === "") {
      setPasswordError(true);
    }
  };

  const handleSignIn = async () => {
    handleError();
    const payload = {
      email,
      password,
    };
    if (email !== "" && password !== "") {
      displayLoader();
      const response: any = await signin(payload);
      if (response instanceof Error) {
        //@ts-ignore
        toast.error(response?.response?.data?.errors);
        hideLoader();
      } else if (response?.data?.status) {
        navigate("/send-otp");
        setData(response?.data?.data?.user);
        hideLoader();
      } else {
        hideLoader();
      }
    }
  };

  return (
    <div>
      <Navbar />
      <div className="background-container">
        <div className="text-wrap">
          <p className="benvenuti">
            {t("Welcome in ASB")} <br /> {t("Axion SICAV!")}
          </p>
          <p className="benvenutii">{t("Benvenuti in ASB Axion SICAV!")}</p>

          <h6>
            {t("To access the portal we kindly invite you")} <br />{" "}
            {t("to read the legal disclaimer and to accept")}
            <br /> {t("the user's conditions")}
          </h6>
        </div>
        <div className="login-box">
          <div className="login-box-blur d-flex align-items-center justify-content-center">
            <div className="login-box-content">
              <p className="text-center" style={{ fontSize: "24px" }}>
                Sign In
              </p>
              <div>
                <div className="d-flex justify-content-center">
                  <input
                    id="email"
                    type="text"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                {emailError && <p className="required-text">Required</p>}

                <div className="d-flex justify-content-center">
                  <input
                    type={eyeOpen ? "text" : "password"}
                    placeholder="Enter password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {eyeOpen ? (
                    <i className="eye-icon" onClick={() => setEyeOpen(false)}>
                      <IoEyeOutline />
                    </i>
                  ) : (
                    <i className="eye-icon" onClick={() => setEyeOpen(true)}>
                      <IoEyeOffOutline />
                    </i>
                  )}
                </div>
                {passwordError && <p className="required-text">Required</p>}
                <p className="forgot-password" onClick={handleNavigate}>
                  Forgot Password
                </p>
              </div>
              <div className="confirm-box-btns mt-4">
                <button className="btn2" onClick={handleSignIn}>
                  Sign In
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div style={{ marginTop: "250px" }}>
        <Footer />
      </div> */}
    </div>
  );
};

export default Login;
