import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { forgotPassword } from "../../apis/api-endpoint";
import useStore from "../../store";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { displayLoader, hideLoader } = useStore();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);

  useEffect(() => {
    if (email !== "") {
      setEmailError(false);
    }
  }, [email]);

  const handleError = () => {
    if (email === "") {
      setEmailError(true);
    }
  };

  const handleForgotPassword = async () => {
    handleError();
    if (email !== "") {
      displayLoader();
      const response = await forgotPassword({ email });
      if (response?.status) {
        hideLoader();
      } else if (!response?.status) {
        hideLoader();
      }
    }
  };

  const handleNavigate = () => {
    navigate(`/login`);
  };
  return (
    <div>
      <Navbar />
      <div className="background-container">
        <div className="text-wrap">
          <p className="benvenuti">
            {t("Welcome in ASB")} <br /> {t("Axion SICAV!")}
          </p>
          <p className="benvenutii">{t("Benvenuti in ASB Axion SICAV!")}</p>

          <h6>
            {t("To access the portal we kindly invite you")} <br />{" "}
            {t("to read the legal disclaimer and to accept")}
            <br /> {t("the user's conditions")}
          </h6>
        </div>
        <div className="login-box">
          <div className="login-box-blur d-flex align-items-center justify-content-center">
            <div className="login-box-content">
              <p className="text-center" style={{ fontSize: "24px" }}>
                Forgot Password
              </p>
              <div>
                <div className="d-flex justify-content-center">
                  <input
                    id="email"
                    type="text"
                    placeholder="Enter valid email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                {emailError && <p className="required-text">Required</p>}
              </div>
              <div className="confirm-box-btns" style={{ margin: 0 }}>
                <button className="btn2" onClick={handleForgotPassword}>
                  Send Reset Link
                </button>
              </div>
              <div className="d-flex justify-content-center mt-3 gap-2 rem-pas">
                <p
                  style={{
                    textAlign: "end",
                    fontSize: "12px",
                  }}
                >
                  Remember Password?
                </p>
                <p
                  className="forgot-password"
                  style={{ margin: 0, marginLeft: "-100px" }}
                  onClick={handleNavigate}
                >
                  Sign In
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
