import React from "react";
import "./App.css";
import Home from "./components/Home";
import Disclaim from "./components/Disclaim.tsx";
import Optimization from "./components/Optimization";
import Funds from "./components/Funds";
import Login from "./components/Login";
import ForgotPassword from "./components/Forgot-password";
import ResetPassword from "./components/ResetPassword";
import UploadCSV from "./components/UploadCSV";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import VerifyEmail from "./components/VerifyEmail";
import Loader from "./components/General/views/Loader";
import useStore from "./store";
import SendOTP from "./components/SendOTP";
import LegalNotice from "./components/LegalNotice";
import Conditions from "./components/Conditions";
import Privacy from "./components/Privacy";
import Cookies from "./components/Cookies";
import { Toaster } from "react-hot-toast";
import MarketView from "./components/MarketView";

function App() {
  const { loader } = useStore();
  const token = localStorage.getItem("token");
  return (
    <div className="container-fluid">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="sicavdisclaim" element={<Disclaim />} />
          <Route path="optimization" element={<Optimization />} />
          <Route path="funds/:id" element={<Funds />} />
          <Route path="login" element={<Login />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="reset-password/:token" element={<ResetPassword />} />
          {token && <Route path="admin" element={<UploadCSV />} />}
          <Route path="verify-otp" element={<VerifyEmail />} />
          <Route path="send-otp" element={<SendOTP />} />
          <Route path="avvertenze-legali" element={<LegalNotice />} />
          <Route path="condizioni" element={<Conditions />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="cookies" element={<Cookies />} />
          <Route path="market-view" element={<MarketView />} />
        </Routes>
      </BrowserRouter>
      <Loader display={loader} />
      <Toaster position="top-right" reverseOrder={false} />
    </div>
  );
}

export default App;
