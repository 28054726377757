import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import { useTranslation } from "react-i18next";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { resetPassword } from "../../apis/api-endpoint";
import { useParams } from "react-router-dom";
import useStore from "../../store";

const ResetPassword = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  let { token } = useParams();
  const { displayLoader, hideLoader } = useStore();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [eyeOpenNew, setEyeOpenNew] = useState(false);
  const [eyeOpenConfirm, setEyeOpenConfirm] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [matchPassword, setMatchPassword] = useState(false);

  useEffect(() => {
    if (newPassword !== "") {
      setNewPasswordError(false);
    }
    if (confirmPassword !== "") {
      setConfirmPasswordError(false);
    }
    if (newPassword === confirmPassword) {
      setMatchPassword(false);
    }
  }, [newPassword, confirmPassword]);

  const handleValidation = () => {
    if (newPassword === "") {
      setNewPasswordError(true);
    } else if (confirmPassword === "") {
      setConfirmPasswordError(true);
    } else if (newPassword !== confirmPassword) {
      setMatchPassword(true);
    }
  };

  const handleResetPassword = async () => {
    handleValidation();
    if (
      newPassword !== "" &&
      confirmPassword !== "" &&
      newPassword === confirmPassword
    ) {
      displayLoader();
      const body = {
        token,
        payload: {
          password: newPassword,
        },
      };
      const response = await resetPassword(body);
      if (response?.status) {
        navigate("/");
        hideLoader();
      } else if (!response?.status) {
        hideLoader();
      }
    }
  };
  return (
    <div>
      <Navbar />
      <div className="background-container">
        <div className="text-wrap">
          <p className="benvenuti">
            {t("Welcome in ASB")} <br /> {t("Axion SICAV!")}
          </p>
          <p className="benvenutii">{t("Benvenuti in ASB Axion SICAV!")}</p>

          <h6>
            {t("To access the portal we kindly invite you")} <br />{" "}
            {t("to read the legal disclaimer and to accept")}
            <br /> {t("the user's conditions")}
          </h6>
        </div>
        <div className="login-box">
          <div className="login-box-blur d-flex align-items-center justify-content-center">
            <div className="login-box-content">
              <p className="text-center" style={{ fontSize: "24px" }}>
                Reset Password
              </p>
              <div>
                <div>
                  <input
                    type={eyeOpenNew ? "text" : "password"}
                    placeholder="Enter new password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                  {eyeOpenNew ? (
                    <i
                      className="eye-icon"
                      onClick={() => setEyeOpenNew(false)}
                    >
                      <IoEyeOutline />
                    </i>
                  ) : (
                    <i className="eye-icon" onClick={() => setEyeOpenNew(true)}>
                      <IoEyeOffOutline />
                    </i>
                  )}
                </div>
                {newPasswordError && <p className="required-text">Required</p>}
                <div>
                  <input
                    type={eyeOpenConfirm ? "text" : "password"}
                    placeholder="Enter confirm password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  {eyeOpenConfirm ? (
                    <i
                      className="eye-icon"
                      onClick={() => setEyeOpenConfirm(false)}
                    >
                      <IoEyeOutline />
                    </i>
                  ) : (
                    <i
                      className="eye-icon"
                      onClick={() => setEyeOpenConfirm(true)}
                    >
                      <IoEyeOffOutline />
                    </i>
                  )}
                </div>
                {confirmPasswordError && (
                  <p className="required-text">Required</p>
                )}
                {matchPassword && (
                  <p className="required-text">
                    Your password & confirm password do not match
                  </p>
                )}
              </div>
              <div className="confirm-box-btns mt-4">
                <button className="btn2" onClick={handleResetPassword}>
                  Reset
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
