// store.js
import {create} from 'zustand';

interface State {
    loader: boolean;
    userData: any;
    code: string;
    fundName:string
  }
interface Actions {
    displayLoader: () => void;
    hideLoader: () => void;
    setData: (data: any) => void;
    setCode:(data: string) => void
    setFundName:(data: string) => void
  }
  

// Define your store
const useStore = create<State & Actions>((set) => ({
  loader: false,
  userData:null,
  code:"",
  fundName:"",
  displayLoader: () => set((state) => ({ loader: state.loader= true })),
  hideLoader: () => set((state) => ({ loader: state.loader =false })),
  setData: (userData) => set({ userData }),
  setCode: (code) => set({ code }),
  setFundName:(fundName) => set({fundName}) 
}));

export default useStore;
