import React, { useEffect, useState, useRef } from "react";
import Logo from "../../assets/images/new-logo.png";
import { Link } from "react-router-dom";
import Burger from "../../assets/images/burger.png";
import CrossIcon from "../../assets/images/cross.png";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Globe from "../../assets/images/globe.png";
import RedGlobe from "../../assets/images/redGlobe.png";
import { useTranslation } from "react-i18next";
import { CiLogout } from "react-icons/ci";
import Profile from "../../assets/images/profileImage.png";
// import { IoPersonOutline } from "react-icons/io5";

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  let { token } = useParams();
  const { t, i18n } = useTranslation();
  const myRef: any = useRef();
  const [menuOpen, setMenuOpen] = useState(false);
  const [active, setActive] = useState(false);
  const [hideMenu, setHideMenu] = useState(false);
  const [hideProfile, setHideProfile] = useState(false);

  useEffect(() => {
    if (
      pathname === "/login" ||
      pathname === "/forgot-password" ||
      pathname === `/reset-password${token}` ||
      pathname === "/admin" ||
      pathname === "/verify-otp" ||
      pathname === "/send-otp"
    ) {
      setHideMenu(true);
    } else {
      setHideMenu(false);
    }
  }, [pathname, token]);

  const handleClickOutside = (e: any) => {
    if (!!myRef.current && !myRef.current.contains(e.target)) {
      setHideProfile(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  const handleMenuOpen = () => {
    setMenuOpen(true);
  };
  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const handleNavigate = () => {
    navigate(`/`);
  };

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    setActive(false);
    setMenuOpen(false);
  };

  const handleProfileMenu = () => {
    if (hideProfile) {
      setHideProfile(false);
    } else {
      setHideProfile(true);
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };

  return (
    <>
      {menuOpen && (
        <div className="main-menu">
          <div className="red-background">
            <div className="crossicon d-flex justify-content-end">
              <img
                src={CrossIcon}
                alt="cross icon"
                onClick={handleMenuClose}
                className="menu-img"
              />
            </div>
            <div className="menu-list d-flex align-items-center justify-content-center">
              <div>
                <Link to="/sicavdisclaim">
                  <p>ACCESSO AI FONDI</p>
                </Link>
                {pathname !== "/" ? (
                  <Link to="/" onClick={handleMenuClose}>
                    <p>CHI SIAMO</p>
                  </Link>
                ) : (
                  <a href="#chisiamo" onClick={handleMenuClose}>
                    <p>CHI SIAMO</p>
                  </a>
                )}

                {pathname !== "/" ? (
                  <Link to="/" onClick={handleMenuClose}>
                    <p>MISSION</p>
                  </Link>
                ) : (
                  <a href="#mission" onClick={handleMenuClose}>
                    <p>MISSION</p>
                  </a>
                )}

                {pathname !== "/" ? (
                  <Link to="/" onClick={handleMenuClose}>
                    <p>ORGANIZZAZIONE</p>
                  </Link>
                ) : (
                  <a href="#organizzazione" onClick={handleMenuClose}>
                    <p>ORGANIZZAZIONE</p>
                  </a>
                )}

                {pathname !== "/" ? (
                  <Link to="/" onClick={handleMenuClose}>
                    <p>{t("SUSTAINABLE INVESTMENTS")}</p>
                  </Link>
                ) : (
                  <a href="#sustainable" onClick={handleMenuClose}>
                    <p>{t("SUSTAINABLE INVESTMENTS")}</p>
                  </a>
                )}
                {/* {pathname !== "/" ? ( */}
                <Link to="/market-view" onClick={handleMenuClose}>
                  <p>MARKET VIEW</p>
                </Link>
                {/* ) : (
                  <a href="#sustainable" onClick={handleMenuClose}>
                    <p>{t("SUSTAINABLE INVESTMENTS")}</p>
                  </a>
                )} */}

                <p onClick={() => changeLanguage("it")}>ITALIANO</p>
                <p onClick={() => changeLanguage("en")}>ENGLISH</p>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className="nav-main"
        style={pathname === "/admin" ? { height: "80px" } : undefined}
      >
        <div
          className={`${
            pathname === "/admin" ? "nav-admin-container" : "nav-container"
          }`}
        >
          <div
            className="row align-items-center justify-content-between nav-menu"
            style={pathname === "/admin" ? { height: "80px" } : undefined}
          >
            <div className={active ? "col-9" : "col-3"}>
              <img
                src={Logo}
                alt="logo"
                className="logo-img"
                onClick={handleNavigate}
                style={pathname === "/admin" ? { height: "35px" } : undefined}
              />
            </div>
            <div className={active ? "col-3" : "col-9"}>
              <div className="nav-item">
                <div className="d-flex flex-row gap-4 justify-content-end align-items-center">
                  {pathname !== "/" ? (
                    <Link to="/">
                      {!active && !hideMenu && (
                        <p className="nav-text">{t("WHO WE ARE")}</p>
                      )}
                    </Link>
                  ) : (
                    <a href="#chisiamo">
                      {!active && !hideMenu && (
                        <p className="nav-text">{t("WHO WE ARE")}</p>
                      )}
                    </a>
                  )}

                  {pathname !== "/" ? (
                    <Link to="/">
                      {!active && !hideMenu && (
                        <p className="nav-text">MISSION</p>
                      )}
                    </Link>
                  ) : (
                    <a href="#mission">
                      {!active && !hideMenu && (
                        <p className="nav-text">MISSION</p>
                      )}
                    </a>
                  )}

                  {pathname !== "/" ? (
                    <Link to="/">
                      {!active && !hideMenu && (
                        <p className="nav-text">{t("ORGANIZATION")}</p>
                      )}
                    </Link>
                  ) : (
                    <a href="#organizzazione">
                      {!active && !hideMenu && (
                        <p className="nav-text">{t("ORGANIZATION")}</p>
                      )}
                    </a>
                  )}

                  {pathname !== "/" ? (
                    <Link to="/">
                      {!active && !hideMenu && (
                        <p className="nav-text">
                          {t("SUSTAINABLE INVESTMENTS")}
                        </p>
                      )}
                    </Link>
                  ) : (
                    <a href="#sustainable">
                      {!active && !hideMenu && (
                        <p className="nav-text">
                          {t("SUSTAINABLE INVESTMENTS")}
                        </p>
                      )}
                    </a>
                  )}

                  {/* {pathname !== "/" ? ( */}
                  <Link to="/market-view">
                    {!active && !hideMenu && (
                      <p className="nav-text">MARKET VIEW</p>
                    )}
                  </Link>
                  {/* ) : (
                    <a href="#sustainable">
                      {!active && !hideMenu && (
                        <p className="nav-text">MARKET PLACE</p>
                      )}
                    </a>
                  )} */}

                  {active && (
                    <p
                      className="nav-text"
                      style={{ color: "#ff0019" }}
                      onClick={() => changeLanguage("it")}
                    >
                      ITALIANO
                    </p>
                  )}
                  {active && (
                    <div
                      style={{
                        width: "1px",
                        height: "30px",
                        border: "1px solid #ff0019",
                      }}
                    />
                  )}
                  {active && (
                    <p
                      className="nav-text"
                      style={{ color: "#ff0019" }}
                      onClick={() => changeLanguage("en")}
                    >
                      ENGLISH
                    </p>
                  )}
                  {!active && (
                    <img
                      src={Globe}
                      alt="globe"
                      height={35}
                      onClick={() => setActive(true)}
                      style={{ cursor: "pointer" }}
                    />
                  )}
                  {active && (
                    <img
                      src={RedGlobe}
                      alt="globe"
                      height={35}
                      onClick={() => setActive(false)}
                      style={{ cursor: "pointer" }}
                    />
                  )}
                  {pathname === "/admin" && (
                    <div>
                      <div
                        style={{
                          height: "40px",
                          width: "40px",
                          // background: "red",
                          borderRadius: "50px",
                          cursor: "pointer",
                        }}
                        onClick={handleProfileMenu}
                      >
                        <img
                          src={Profile}
                          alt="profileImg"
                          height={40}
                          width={40}
                        ></img>
                      </div>
                      {hideProfile && (
                        <div className="profile-menu" ref={myRef}>
                          {/* <div
                            className="d-flex align-items-center justify-content-center gap-3"
                            style={{ borderBottom: "1px solid #dadada" }}
                          >
                            <i>
                              <IoPersonOutline />
                            </i>
                            <p className="mt-3">Profile</p>
                          </div> */}
                          <div className="d-flex align-items-center justify-content-center gap-3">
                            <i>
                              <CiLogout />
                            </i>
                            <p className="mt-3" onClick={handleLogout}>
                              Logout
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>

              <div className="burger ">
                <div className="d-flex justify-content-end">
                  <img src={Burger} alt="buger" onClick={handleMenuOpen} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
