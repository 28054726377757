import React from "react";
import { useTranslation } from "react-i18next";
import useStore from "../../store";

const PortfolioTable = ({ fiteredFortfolio, ccy }: any) => {
  const { t } = useTranslation();
  const { fundName } = useStore();
  const splitFundName = fundName?.split("-");
  return (
    <>
      <p className="analisi-txt">
        {t("Top 10 Holdings (%) of ASB Axion SICAV")}
      </p>
      <p className="eru-cls-txt">{`${splitFundName?.[1]} ${ccy} class`}</p>
      <div className="funds-table">
        <table className="table pano-tab">
          <thead>
            <tr>
              <th scope="col">{t("TOP 10 SINGLE HOLDINGS")}</th>
              <th scope="col">{t("SECTOR")}</th>
              <th scope="col">{t("COUNTRY")}</th>
              <th scope="col">{t("WEIGHT")}</th>
            </tr>
          </thead>
          <tbody>
            {fiteredFortfolio?.map((items: any) => {
              return (
                <tr key={items?.id}>
                  <th scope="row">{items?.title}</th>
                  <td>{items?.sector}</td>
                  <td>{items?.country}</td>
                  <td>{items?.weight}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="aggiornati-txt-wrap">
        <p className="paragraph-text">
          {t(
            "Top 10 Holdings (%) of ASB Axion SICAVData Source: Axion SWISS Bank SA. Reference to portfolio holdings shall not be considered as a recommendation to buy or sell any"
          )}{" "}
          <br />{" "}
          {t(
            "security and securities are subject to risk. They are subject to change and should not be relied upon as a view of the portfolio's exposure."
          )}
        </p>
      </div>
    </>
  );
};

export default PortfolioTable;
