import React, { useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Privacy = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Navbar />

      <div className="privacy-main">
        <p className="red-text">{t("Privacy")}</p>
        <p className="paragraph-text">
          {t("Welcome to")}{" "}
          <a href="https://www.axionsicav.com" target="__blank">
            www.axionsicav.com
          </a>{" "}
          {t("hereafter the Website")}
          <br /> {t("privacy para 1")} <br />
          {t("privacy para 2")}
          <br />
          {t("privacy para 3")}
        </p>
        <div>
          <p className="fw-bold">{t("A. Personal information")}</p>
          <p className="paragraph-text">{t("Personal information para 1")}</p>
        </div>
        <div>
          <p className="fw-bold">
            {t("B. Collecting and processing personal information - Cookies")}
          </p>
          <p className="paragraph-text">
            {t(
              "Collecting and processing personal information - Cookies para1"
            )}{" "}
            <br />
            {t(
              "Collecting and processing personal information - Cookies para2"
            )}
          </p>
          <p className="paragraph-text">
            {t(
              "Collecting and processing personal information - Cookies para3"
            )}{" "}
            <br />
            {t(
              "Collecting and processing personal information - Cookies para4"
            )}
            <br />
            {t(
              "Collecting and processing personal information - Cookies para5"
            )}
            <br />
            {t(
              "Collecting and processing personal information - Cookies para6"
            )}
            <br />
            {t(
              "Collecting and processing personal information - Cookies para7"
            )}
          </p>
        </div>
        <div>
          <p className="fw-bold">
            {t("C. Personal information transmission and storage")}
          </p>
          <p className="paragraph-text">
            {t("Personal information transmission and storage para 1")}
          </p>
        </div>
        <div>
          <p className="fw-bold">
            {t("D. Consent to collect and process personal information")}
          </p>
          <p className="paragraph-text">
            {t("Consent to collect and process personal information para1")}
            <br />
            {t("Consent to collect and process personal information para2")}
            <br />
            {t("Consent to collect and process personal information para3")}
          </p>
        </div>
        <div>
          <p className="fw-bold">{t("E. Security")}</p>
          <p className="paragraph-text">
            {t("Security para1")}
            <br />
            {t("Security para2")}
            <br />
            {t("Security para3")}
          </p>
        </div>
        <div>
          <p className="fw-bold">{t("F. Access to personal information")}</p>
          <p className="paragraph-text">
            {t("Access to personal information para 1")}
          </p>
        </div>
        <div>
          <p className="fw-bold">
            {t("G. Links to third party websites and resources")}
          </p>
          <p className="paragraph-text">
            {t("Links to third party websites and resources para 1")}
          </p>
        </div>
        <div>
          <p className="fw-bold">{t("H. Website ownership")}</p>
          <p className="paragraph-text">{t("Website ownership para 1")}</p>
        </div>
        <div>
          <p className="fw-bold">{t("I. Contact us")}</p>
          <p className="paragraph-text">{t("Contact us para 1")}</p>
        </div>
        <div>
          <p className="fw-bold">{t("J. Adoption and editing")}</p>
          <p className="paragraph-text">{t("Adoption and editing 1")}</p>
        </div>

        <div className="mt-5" style={{ lineHeight: "4px" }}>
          <p
            className="privacy-txt"
            onClick={() => navigate(`/avvertenze-legali`)}
          >
            {t("General Warnings")}
          </p>
          <p className="privacy-txt" onClick={() => navigate(`/condizioni`)}>
            {t("Conditions")}
          </p>
          <p className="privacy-txt" onClick={() => navigate(`/privacy`)}>
            {t("Privacy")}
          </p>
          <p className="privacy-txt" onClick={() => navigate(`/cookies`)}>
            {t("Cookies1")}
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Privacy;
