import React from "react";
import { useTranslation } from "react-i18next";
import useStore from "../../store";

const SixMonthTable = ({ performanceData, ccy }: any) => {
  const { fundName } = useStore();
  const { t } = useTranslation();
  const splitFundName = fundName?.split("-");

  // Function to dynamically generate table headers based on available performance years
  const generateTableHeaders = () => {
    const years = performanceData?.reduce((acc: Set<string>, analysis: any) => {
      analysis.performanceYear.forEach((yearObj: any) => acc.add(yearObj.year));
      return acc;
    }, new Set<string>());
    return Array.from(years)
      .sort()
      .map((year: any) => (
        <th key={year} scope="col">
          {year}
        </th>
      ));
  };

  // Function to get performance value for a specific year
  const getPerformanceValue = (performanceYear: any[], year: string) => {
    const performance = performanceYear.find((item) => item.year === year);
    return performance ? performance.value : "-";
  };

  return (
    <>
      <p className="analisi-txt">
        {t("Analysis of performance (net) of ASB Axion SICAV")}
      </p>
      <p className="eru-cls-txt">{`${splitFundName?.[1]} ${ccy} class`}</p>
      <div className="funds-table">
        <table className="table pano-tab">
          <thead>
            <tr>
              <th scope="col">{t("FUNDS")}</th>
              <th scope="col">{t("SHARE CLASS")}</th>
              <th scope="col">ISIN</th>
              <th scope="col">BLOOMBERG</th>
              {generateTableHeaders()}
            </tr>
          </thead>
          <tbody>
            {performanceData?.map((analysis: any) => {
              if (analysis?.category === "6 Months") {
                return (
                  <tr key={analysis?.isin}>
                    <th scope="row">{fundName}</th>
                    <td>{analysis?.shareClass}</td>
                    <td>{analysis?.isin}</td>
                    <td>{analysis?.bloomberg}</td>
                    {Array.from(generateTableHeaders()).map(
                      (header: any, index: number) => (
                        <td key={index}>
                          {getPerformanceValue(
                            analysis.performanceYear,
                            header.key
                          )}
                        </td>
                      )
                    )}
                  </tr>
                );
              }
              return null;
            })}
          </tbody>
        </table>
      </div>
      <div className="aggiornati-txt-wrap">
        <p className="paragraph-text">
          {t(
            "Data source: Axion SWISS Bank SA. Data updated to the previous business day."
          )}
          <br />{" "}
          {t(
            "Past performance does not guarantee and is not indicative of future results."
          )}
        </p>
      </div>
    </>
  );
};

export default SixMonthTable;
