import React from "react";
import { useTranslation } from "react-i18next";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";
import { BreakdownItem } from "../Types";
import useStore from "../../store";

const RatingChart = ({
  ratingData,
  ccy,
}: {
  ratingData: BreakdownItem[];
  ccy: string;
}) => {
  const rating = ratingData?.map((item: BreakdownItem) => ({
    ...item,
    value: parseFloat(item.value),
  }));
  const { t } = useTranslation();
  const { fundName } = useStore();
  const splitFundName = fundName?.split("-");
  return (
    <>
      <p className="andamento-txt">
        {t("Rating breakdown (%) of ASB Axion SICAV")}
      </p>
      <p className="kermata-txt">{`${splitFundName?.[1]} ${ccy} class`}</p>
      <div className="d-flex justify-content-center align-items-center chart gap-3">
        <BarChart width={1200} height={600} data={rating} layout="vertical">
          <CartesianGrid />
          <XAxis
            type="number"
            tick={{ fontSize: 18 }}
            interval={0}
            tickMargin={10}
          />
          <YAxis
            dataKey="label"
            type="category"
            tick={{ fontSize: 18 }}
            interval={0}
            tickMargin={10}
          />
          <Tooltip />

          <Bar dataKey="value" fill="red" barSize={45} />
        </BarChart>
      </div>
      <p className="passate-txt">
        {t(
          "Data source: Axion SWISS Bank SA. Data updated to the last closing month. Rating might not be representative of the investment quality and it might be subject to change without prior notice."
        )}
      </p>
    </>
  );
};

export default RatingChart;
