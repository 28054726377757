import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getAllFundDetail, getAllFundsNames } from "../../apis/api-endpoint";
import DOCUMENTATION from "../Documention";
import RiskIndex from "../RiskIndex";
import useStore from "../../store";
import { BiMenuAltRight } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import AnnuallyTable from "../FundsDetailsTables/AnnuallyTable";
import SixMonthTable from "../FundsDetailsTables/SixMonthTable";
import PortfolioTable from "../FundsDetailsTables/PortfolioTable";
import PerformanceLineChart from "../FundsCharts/PerformanceLineChart";
import SectorAreaBarChart from "../FundsCharts/SectorAreaBarChart";
import GeographicalBarChart from "../FundsCharts/GeographicalBarChart";
import RatingChart from "../FundsCharts/RatingChart";
import {
  PerformanceAnalysis,
  TopPortfolioItem,
  BreakdownItem,
  PdfLinkDetailFund,
  FundPerformance,
} from "../Types";

const Funds = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const {
    displayLoader,
    hideLoader,
    //  code
    fundName,
    setFundName,
  } = useStore();
  const [riskIndex, setRiskIndex] = useState<string>("");
  const [performanceData, setPerformanceData] = useState<PerformanceAnalysis[]>(
    []
  );
  const [topFortfolio, setTopFortfolio] = useState<TopPortfolioItem[]>([]);
  const [fiteredFortfolio, setFilteredFortfolio] = useState<TopPortfolioItem[]>(
    []
  );
  const [fundsPerformance, setFundsPerformance] = useState<FundPerformance[]>(
    []
  );
  const [breakdown, setBreakDown] = useState<BreakdownItem[]>([]);
  const [ratingData, setRationData] = useState<BreakdownItem[]>([]);
  const [geographicalData, setGeographicalData] = useState<BreakdownItem[]>([]);
  const [filteredGeographical, setFilteredGeographical] = useState<any>([]);
  const [sectorAreaData, setSectorAreaData] = useState<BreakdownItem[]>([]);
  const [filteredSectorAreaData, setFilteredSectorAreaData] = useState<any>([]);
  const [openFunds, setOpenFunds] = useState(false);
  const [allFunds, setAllFunds] = useState([]);
  const [pdfLinks, setPdfLinks] = useState<PdfLinkDetailFund[]>([]);
  const [fundOverViewEng, setFundOverViewEng] = useState<string>("");
  const [fundOverViewIt, setFundOverViewIt] = useState<string>("");
  const [ccy, setCcy] = useState<string>("");
  const [annuallyTableData, setAnnuallyTableData] = useState([]);

  const fundShortOverViewIt = fundOverViewIt?.split(".");
  const fundShortOverViewEng = fundOverViewEng?.split(".");

  const linChartData = fundsPerformance?.map((item: FundPerformance) => ({
    ...item,
    value: parseFloat(item.value),
  }));

  useEffect(() => {
    if (i18n.language === "en") {
      const englishPortFolio = topFortfolio?.filter((el: TopPortfolioItem) => {
        return el?.language === "english";
      });
      setFilteredFortfolio(englishPortFolio);
    } else {
      const italianPortFolio = topFortfolio?.filter((el: TopPortfolioItem) => {
        return el?.language === "italian";
      });
      setFilteredFortfolio(italianPortFolio);
    }
  }, [topFortfolio, i18n.language]);

  useEffect(() => {
    const byRatingData = breakdown?.filter((el: BreakdownItem) => {
      return el?.type === "By rating";
    });
    setRationData(byRatingData);
    const byGeographicalData = breakdown?.filter((el: BreakdownItem) => {
      return el?.type === "By Geographical area";
    });
    setGeographicalData(byGeographicalData);
    const bySectorAreaData = breakdown?.filter((el: BreakdownItem) => {
      return el?.type === "By Sector Area";
    });
    setSectorAreaData(bySectorAreaData);
  }, [breakdown]);

  useEffect(() => {
    if (i18n.language === "en") {
      const englishGeographical = geographicalData?.filter(
        (el: BreakdownItem) => {
          return el?.language === "english";
        }
      );
      const newData = englishGeographical?.map((item: BreakdownItem) => ({
        ...item,
        value: parseFloat(item.value),
      }));
      setFilteredGeographical(newData);
    } else {
      const italianGeographical = geographicalData?.filter(
        (el: BreakdownItem) => {
          return el?.language === "italian";
        }
      );
      const newData = italianGeographical?.map((item: BreakdownItem) => ({
        ...item,
        value: parseFloat(item.value),
      }));
      setFilteredGeographical(newData);
    }
  }, [geographicalData, i18n.language]);

  useEffect(() => {
    if (i18n.language === "en") {
      const englishSectorArea = sectorAreaData?.filter((el: BreakdownItem) => {
        return el?.language === "english";
      });
      const newData = englishSectorArea?.map((item: BreakdownItem) => ({
        ...item,
        value: parseFloat(item.value),
      }));
      setFilteredSectorAreaData(newData);
    } else {
      const italianPortFolio = sectorAreaData?.filter((el: BreakdownItem) => {
        return el?.language === "italian";
      });
      const newData = italianPortFolio?.map((item: BreakdownItem) => ({
        ...item,
        value: parseFloat(item.value),
      }));
      setFilteredSectorAreaData(newData);
    }
  }, [sectorAreaData, i18n.language]);

  useEffect(() => {
    const handleFundsDetail = async () => {
      displayLoader();
      if (id) {
        try {
          const response = await getAllFundDetail(id);
          if (response?.status) {
            setPerformanceData(
              response?.data?.fundDetails?.performanceAnalysis
            );
            setTopFortfolio(response?.data?.fundDetails?.top10Portfolio);
            setFundsPerformance(response?.data?.fundDetails?.fundsPerformance);
            setBreakDown(response?.data?.fundDetails?.breakdown);
            setRiskIndex(response?.data?.fundDetails?.riskIndex);
            setFundName(response?.data?.fundDetails?.name);
            setPdfLinks(response?.data?.pdfLinks);
            setFundOverViewEng(response?.data?.fundDetails?.fundOverviewInEng);
            setFundOverViewIt(response?.data?.fundDetails?.fundOverviewInIta);
            setCcy(response?.data?.fundDetails?.ccy);
            setAnnuallyTableData(
              response?.data?.fundDetails?.annuallyFundsDetails
            );
            hideLoader();
          } else {
            hideLoader();
            // Handle the case when response is undefined or falsy
          }
        } catch (error) {
          hideLoader();
          toast.error("An error occurred while fetching fund details.");
        }
      }
    };

    handleFundsDetail();
  }, [id, displayLoader, hideLoader, setFundName]);

  useEffect(() => {
    const fundsName = async () => {
      displayLoader();
      const response = await getAllFundsNames();
      if (response?.status) {
        setAllFunds(response.data.fundDetails);
        hideLoader();
      } else if (!response?.status) {
        hideLoader();
      }
    };
    fundsName();
  }, [displayLoader, hideLoader]);

  const handleFundsDetailFromMenu = async (isin: string, fund: string) => {
    if (isin) {
      displayLoader();
      const response = await getAllFundDetail(isin);
      if (response?.status) {
        setPerformanceData(response?.data?.fundDetails?.performanceAnalysis);
        setTopFortfolio(response?.data?.fundDetails?.top10Portfolio);
        setFundsPerformance(response?.data?.fundDetails?.fundsPerformance);
        setBreakDown(response?.data?.fundDetails?.breakdown);
        setRiskIndex(response?.data?.fundDetails?.riskIndex);
        setFundName(response?.data?.fundDetails?.name);
        setPdfLinks(response?.data?.pdfLinks);
        setFundOverViewEng(response?.data?.fundDetails?.fundOverviewInEng);
        setFundOverViewIt(response?.data?.fundDetails?.fundOverviewInIta);
        setCcy(response?.data?.fundDetails?.ccy);
        setAnnuallyTableData(response?.data?.fundDetails?.annuallyFundsDetails);
        hideLoader();
      } else if (response?.status === false) {
        hideLoader();
      }
    }
    setFundName(fund);
  };

  return (
    <div>
      <Navbar />
      <div className="red-box d-flex align-items-center justify-content-between">
        <div className="red-box-content">
          <p className="kermata-fund-txt">{fundName}</p>
          {/* <p className="eur-cap-txt">
            {fundName === "Asb Axion Sicav - Kermata Fund"
              ? "EUR - CAP"
              : fundName === "Asb Axion Sicav - Unconstrained Bond Fund"
              ? "CAP CHF-EUR"
              : fundName === "Asb Axion Sicav - BancaStato Azionario Globale Fd"
              ? "EUR - CAP"
              : fundName === "Asb Axion Sicav - Bancastato Reddito Fund"
              ? "CAP CHF-EUR - DIS CHF-EUR"
              : fundName === "Asb Axion Sicav - Bancastato Bilanciato Fund"
              ? "CAP CHF-EUR - DIS CHF-EUR"
              : "EUR - CAP"}
            
          </p> */}
          <p className="orbiettvo-txt">
            {i18n.language === "en"
              ? `${fundShortOverViewEng?.[0]}.`
              : `${fundShortOverViewIt?.[0]}.`}
          </p>
        </div>
        <div className="fund-main">
          <div className="d-flex justify-content-end">
            {!openFunds && (
              <i onClick={() => setOpenFunds(true)}>
                <BiMenuAltRight />
              </i>
            )}
            {openFunds && (
              <i onClick={() => setOpenFunds(false)}>
                <RxCross2 />
              </i>
            )}
          </div>
          {openFunds && (
            <div>
              <p
                className="funds-text"
                onClick={() => navigate("/optimization")}
              >
                {t("Summary of all funds")}
              </p>
              {allFunds?.map((fund: any) => {
                return (
                  <p
                    key={fund?.code}
                    className="funds-text"
                    onClick={() =>
                      handleFundsDetailFromMenu(
                        fund?.isinCode,
                        fund?.description
                      )
                    }
                  >
                    {fund?.description}
                  </p>
                );
              })}
            </div>
          )}
        </div>
      </div>
      <div>
        <AnnuallyTable performanceData={annuallyTableData} />
      </div>
      <div className="documenti d-flex align-items-center justify-content-center">
        <DOCUMENTATION pdfLinks={pdfLinks} />
      </div>
      <div className="performance-chart">
        <PerformanceLineChart linChartData={linChartData} />
      </div>
      <div className="Objective d-flex align-items-center">
        <RiskIndex
          riskIndex={riskIndex}
          fundOverViewEng={fundOverViewEng}
          fundOverViewIt={fundOverViewIt}
        />
      </div>
      <div>
        <SixMonthTable performanceData={performanceData} ccy={ccy} />
      </div>
      {ratingData.length > 0 && (
        <div className="performance-chart" style={{ background: "#f9f9f9" }}>
          <RatingChart ratingData={ratingData} ccy={ccy} />
        </div>
      )}
      <div>
        <PortfolioTable fiteredFortfolio={fiteredFortfolio} ccy={ccy} />
      </div>
      <div
        className="performance-chart lst-chart"
        style={{ background: "#f9f9f9" }}
      >
        <SectorAreaBarChart
          filteredSectorAreaData={filteredSectorAreaData}
          ccy={ccy}
        />
      </div>
      <div className="performance-chart lst-chart">
        <GeographicalBarChart
          filteredGeographical={filteredGeographical}
          ccy={ccy}
        />
      </div>
      <Footer />
    </div>
  );
};

export default Funds;
